import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './home'
import Login from './login'
import Register from './register'
import MyLink from './mylink'
import PasswordScreen from './password'
import LockedPage from '../../routes/locked.routes'
import { Helmet } from "react-helmet";
import PublicLayout from '../../layouts/public.layout'
// import ResetPassword from './Forget Password/reset_password'
import ForgetPassword from './Forget Password'


const PublicPages = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={<Navigate to="/" replace />} />
        <Route path='/' element={
          <>
            <Helmet>
              <title>Go Pay Here - Simplify How Your Clients Pay You</title>
              <meta name="description" content="Get paid securely with Go Pay Here! Claim your personal payment link, add bank details, wallet info, and social links—all easily shareable with clients. Make it public or protect it with a 4-digit PIN. Manage everything from one dashboard." />
            </Helmet>
            <PublicLayout>
              <Home />
            </PublicLayout>
          </>}
        />

        <Route path='/:username' element={
          <LockedPage />}
        />

        <Route path='/login' element={
          <>
            <Helmet>
              <title>Login to Your Go Pay Here Account</title>
              <meta
                name="description"
                content="Log in to Go Pay Here and access your dashboard to manage payment methods, social links, and profile settings. Keep your payment link secure or public, depending on your needs. Sign in now to stay in control!"
              />
            </Helmet>
            <Login />
          </>}
        />
        <Route path='/register' element={
          <>
            <Helmet>
              <title>Sign Up for Go Pay Here - Create Your Payment Link</title>
              <meta
                name="description"
                content="Create your Go Pay Here account and set up a personalized payment link. Add payment methods, social media links, and profile info. Share your link with clients and control your privacy settings with ease. Sign up today!"
              />
            </Helmet>
            <Register />
          </>
        }
        />

        <Route path='/forget-password/*' element={<ForgetPassword />
          // <>
          //   <Helmet>
          //     <title>Reset Password of Go Pay Here - Create Your Payment Link</title>
          //     <meta
          //       name="description"
          //       content="Create your Go Pay Here account and set up a personalized payment link. Add payment methods, social media links, and profile info. Share your link with clients and control your privacy settings with ease. Sign up today!"
          //     />
          //   </Helmet>
          //   <ForgetPassword />
          // </>
        }
        />

      </Routes>


    </div>
  )
}
export default PublicPages