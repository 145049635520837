import React, { useState } from 'react';
import { BsCircle, BsCircleFill, BsCopy, BsFacebook, BsInstagram, BsLink45Deg, BsLinkedin, BsPinterest, BsReddit, BsSnapchat, BsTiktok, BsTwitter, BsWhatsapp, BsYoutube } from 'react-icons/bs';
import { toast } from "react-toastify";
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';

const LinkCard = ({ Link, onEdit, onDelete, isEditable }) => {


    return (
        <div className="border-[1px] border-background-100 rounded-lg mb-4 h-content relative w-full">
            <div className={`flex gap-4 items-center p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition shadow-xl rounded-lg `}>
                <div className='w-12 h-12'>
                    {Link.icon ?
                        <div className='rounded-md w-12 h-12'>
                            <img className='rounded-md  w-full h-full' src={Link.icon} alt="" />
                        </div> :
                        <div className='rounded-md w-12 h-12'>
                            <img className='rounded-md  w-full h-full' src={'https://logo.clearbit.com/' + Link.url} alt="" />
                        </div>
                    }
                </div>
                <div className='flex justify-between w-full items-center'>
                    <div className='w-full' >
                        <div className='flex gap-2 items-center'>
                            <div > {Link && Link.title ? Link.title : 'Title Link'}</div>
                            <span class=" bg-primary-500 px-2 py-1 text-white text-xs rounded-lg ">
                                {Link && Link.group ? Link.group : 'Link Group'}
                            </span>
                        </div>
                        <div className='text-xs py-1 text-slate-500'>
                            {Link && Link.decs ? Link.decs : 'Link Description'}
                        </div>
                        <a target='_blank' href={Link && Link.url && Link.url} >
                            <div className='text-xs text-slate-400 hover:text-primary-500 duration-300 '>
                                {Link && Link.url ? Link.url : 'https://facebook.com/gopayhere'}
                            </div>
                        </a>
                    </div>
                    {isEditable && isEditable == true && <div>
                        <span className='flex gap-4 text-lg items-center absolute right-0 top-4 bg-white px-2'>
                            <div onClick={onEdit} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-primary-500 hover:scale-95 duration-300 rounded-lg p-3' >
                                <CiEdit />
                            </div>

                            <div onClick={onDelete} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-red-500 hover:scale-95 duration-300 rounded-lg p-3'>
                                <MdDelete />
                            </div>
                        </span>
                    </div>}
                </div>
            </div >
        </div >
    )
}

export default LinkCard