import { Zoom, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from "react-router-dom";
import PublicPages from "./pages/Public Pages";
import Dashboard from "./pages/Dashboard Pages";
import PrivateRoute from "./routes/private.route";

function App() {

  return (
    <main>

      <Routes>
        <Route path="/*" element={<PublicPages />} />
        <Route path="/admin/*" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        } />
      </Routes>

      <ToastContainer
      className={'rounded-full'}
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        transition={Zoom}
      />
      {/* </div> */}



    </main>

  );
}

export default App;
