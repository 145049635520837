import React from 'react'
import PrimaryButton from '../../components/Public/Widgets/button.primary'
import { Link, useNavigate } from 'react-router-dom'

const NoUserFound = () => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col items-center justify-center h-full w-full bg-background-500'>
        <Link to={'/'} className='p-8 h-48'>
            <img className='h-full' src="/logo.png" alt="" />
        </Link>
        <div className='px-12 lg:px-0'>
            <div className='text-3xl font-semibold text-white text-center'>No User Found</div>
            <div className='text-base text-slate-400 text-center py-6'>Seems like you've entered a wrong username</div>
        </div>
        <div className='flex justify-center gap-4'>
            <PrimaryButton className={'!font-semibold tracking-wider'} size={'base'} label={'Login'} loading={false} type="submit" onClick={() => { navigate('/login') }} />
            <PrimaryButton className={'!font-semibold tracking-wider bg-white !text-slate-700 hover:bg-slate-200'} size={'base'} label={'Register'} onClick={() => { navigate('/register') }} />
        </div>
    </div>
    )
}

export default NoUserFound