import { MdInfoOutline } from 'react-icons/md';

const NoData = ({ item }) => {
    return (
        <div
            className={`flex w-full flex-col justify-center items-center max-h-screen min-h-48 px-12  text-center bg-light-100 dark:bg-dark-100`}
        >
            <MdInfoOutline
                size={64}
                className={`text-gray-600 dark:text-gray-400 text-primary`}
            />
            <h2
                className={`text-gray-700 dark:text-gray-300 text-2xl font-bold my-4`}>
                No {item ? item : ' Data '} Found
            </h2>

        </div>
    );
};

export default NoData;