import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/Public/Widgets/button.primary';
import PrimaryInput from '../../components/Public/Widgets/input.primary';
import { toast } from 'react-toastify';
import { PostDataAPIFunction } from '../../layouts/api/post.function';
import { AuthContext } from '../../utils/authContext';
import { throttle } from '../../utils/functions';
import GoogleLoginButton from '../../utils/googleButton';
import { jwtDecode } from 'jwt-decode';

const Register = () => {

  const [rotate, setRotate] = useState({});
  const location = useLocation();
  const username = new URLSearchParams(location.search).get('username');

  const [UserRegister, setUserRegister] = useState({
    image: 'https://res.cloudinary.com/sheikhtabarak/image/upload/v1729937714/default_nbilpg.avif',
    username: username,
    email: '',
    password: '',
  })
  const [google, setGoogle] = useState(false)

  const handleLogin = (credentialResponse) => {
    const decodedUser = jwtDecode(credentialResponse.credential);
    if (decodedUser) {
      setUserRegister({
        email: decodedUser.email,
      })
      setGoogle(true)

      console.log(decodedUser); // Handle the user data
    }
  };

  const onMouseMove = useCallback(
    (id) =>
      throttle((e) => {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const x = e.clientX - box.left;
        const y = e.clientY - box.top;
        const centerX = box.width / 2;
        const centerY = box.height / 2;
        const rotateX = (y - centerY) / 7;
        const rotateY = (centerX - x) / 7;


        setRotate((prev) => ({
          ...prev,
          [id]: { x: rotateX, y: rotateY },
        }));
      }, 100),
    []
  );

  const onMouseLeave = (id) => {
    setRotate((prev) => ({
      ...prev,
      [id]: { x: 0, y: 0 },
    }));
  };

  useEffect(() => {
    console.log(UserRegister)
  }, [UserRegister])


  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { refreshAuth } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const APIResponse = await PostDataAPIFunction({
        Recieveddata: {
          image: UserRegister.image,
          username: UserRegister.username,
          email: UserRegister.email,
          password: UserRegister.password
        },
        callfor: 'auth/register',
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setTimeout(() => {
          setLoading(false);
          toast.success('Account Created: Login to your account')
          navigate('/admin')

        }, 2000);
        const UserData = APIResponse.data.data
        const datatoken = UserData.token;
        const tokenString = JSON.stringify(datatoken);
        let token = datatoken;
        if (token) { token = tokenString.replace(/"/g, ''); }
        console.log(token)
        refreshAuth(token)
      }

      else {
        setLoading(false);
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const valueChange = (label, value) => {
    setUserRegister({
      ...UserRegister,
      [label]: value
    })
  }

  return (
    <div className="flex flex-col-reverse lg:flex-row items-center justify-center h-screen">

      <div className='lg:w-1/2 p-12  bg-background-500 rounded-r-xl h-full flex justify-center items-center absolute lg:static -z-10 lg:z-0'>
        <img
          className='cursor-pointer'
          style={{
            transform: `rotateX(${rotate['personized-link-img']?.x || 0}deg) rotateY(${rotate['personized-link-img']?.y || 0}deg)`,
            transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
            transitionDuration: '1000ms'
          }}
          onMouseMove={onMouseMove('personized-link-img')}
          onMouseLeave={() => onMouseLeave('personized-link-img')}
          src='/personized-link.png' alt="" />
      </div>

      <div className="h-screen py-12 lg:w-1/2 no-scrollbar  overflow-y-scroll px-12 lg:px-28 bg-white shadow-lg rounded-lg">

        <Link to={'/'} >
          <img className='h-16 mb-8' src="/logo-light.png" alt="" />
        </Link>

        <h2 className="text-4xl font-bold">Join Go Pay here</h2>
        <h3 className='mb-6 text-slate-500 py-4'>Sign up for free!</h3>
        <form onSubmit={handleSubmit}>

          <PrimaryInput
            label={'Username'} type={'text'} name={'username'} placeholder={'Username'}
            onChange={(e) => valueChange('username', e.target.value)} value={UserRegister.username}
            icon={
              <svg width="20" height="16" fill='none' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"></path>
                <path d="M11.9998 14C9.15153 14 6.65091 15.3024 5.23341 17.2638C4.48341 18.3016 4.10841 18.8204 4.6654 19.9102C5.2224 21 6.1482 21 7.99981 21H15.9998C17.8514 21 18.7772 21 19.3342 19.9102C19.8912 18.8204 19.5162 18.3016 18.7662 17.2638C17.3487 15.3024 14.8481 14 11.9998 14Z"></path>
              </svg>
            } />


          <PrimaryInput
            disabled={google}
            label={'Email'} type={'email'} name={'email'} placeholder={'Email'}
            onChange={(e) => valueChange('email', e.target.value)} value={UserRegister.email}
            icon={
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=" stroke-current ml-1">
                <path d="M1.87651 3.83325L6.79885 7.07932C8.55702 8.13423 9.43612 8.66169 10.402 8.6387C11.3678 8.61572 12.2208 8.04705 13.9268 6.90971L18.1232 3.83325M8.33317 14.6666H11.6665C14.8092 14.6666 16.3805 14.6666 17.3569 13.6903C18.3332 12.714 18.3332 11.1426 18.3332 7.99992C18.3332 4.85722 18.3332 3.28587 17.3569 2.30956C16.3805 1.33325 14.8092 1.33325 11.6665 1.33325H8.33317C5.19047 1.33325 3.61913 1.33325 2.64281 2.30956C1.6665 3.28587 1.6665 4.85722 1.6665 7.99992C1.6665 11.1426 1.6665 12.714 2.64281 13.6903C3.61913 14.6666 5.19047 14.6666 8.33317 14.6666Z" stroke="" stroke-width="1.5" stroke-linecap="round"></path>
              </svg>
            } />

          <PrimaryInput
            label={'Password'} type={'password'} name={'password'} placeholder={'Password'}
            onChange={(e) => valueChange('password', e.target.value)} value={UserRegister.password}
            icon={
              <svg width="26" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_425_4503)">
                  <path d="M13.9 14.7C13.3 15.3 12.6 15.6 11.8 15.6C11 15.6 10.2 15.3 9.70005 14.7C9.10005 14.1 8.80005 13.4 8.80005 12.6C8.80005 11.8 9.10005 11 9.70005 10.5L11.8 12.6L13.9 14.7Z" className="my-path"></path>
                  <path d="M4 4.7998L19.6 20.3998" stroke-linecap="round" className="my-path"></path>
                  <path d="M17.7 18.4998C16 19.5998 14 20.1998 11.8 20.1998C7.70003 20.1998 4.10002 17.8998 2.30002 14.4998C1.60002 13.3998 1.60002 11.9998 2.30002 10.8998C3.20002 9.19981 4.50002 7.7998 6.10002 6.7998" className="my-path"></path>
                  <path d="M8.40002 5.7001C9.50002 5.3001 10.6 5.1001 11.8 5.1001C15.9 5.1001 19.5 7.4001 21.3 10.8001C21.9 11.9001 21.9 13.3001 21.3 14.4001C20.8 15.3001 20.2 16.1001 19.5 16.8001" stroke-linecap="round" className="my-path"></path>
                </g>
                <defs>
                  <clipPath id="clip0_425_4503">
                    <rect width="21.6" height="17.2" fill="white" transform="translate(1 4)"></rect>
                  </clipPath>
                </defs>
              </svg>
            } />

          <PrimaryButton size={'base'} loading={loading} label="Register" type="submit" />

          <div className='pt-4'>
            <GoogleLoginButton handleLogin={handleLogin} />
          </div>

          <div className='text-xs text-slate-400 py-2'>By clicking Register, you agree to our privacy notice, T&Cs and to receive offers, news and updates.</div>

          <div className='pt-4'>
            <div>Already have an Account? <Link to={'/login'} className='text-primary-500'>Login</Link></div>
          </div>

        </form>

      </div>

    </div>
  );
};

export default Register;