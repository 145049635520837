import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../utils/authContext'
import { copyToClipboard } from '../../../utils/functions'
import { BsCopy } from 'react-icons/bs'
import PrimaryButton from '../../../components/Public/Widgets/button.primary'

const Settings = ({ refreshIframe }) => {
  const [Refresh, setRefresh] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [LinkwithPasscode, setLinkwithPasscode] = useState('')
  const { Auth } = useContext(AuthContext)

  useEffect(() => {
    refreshIframe()
  }, [Refresh])

  const GenetateLink = () => {
    setLoading(true)
    setTimeout(() => {
      setLinkwithPasscode('?passcode=' + Auth.data.profile_password)
      setLoading(false)
    }, 2000);
  }

  return (
    <div>
      <div className='text-primary-500 font-semibold pb-4 text-lg'>Settings</div>

      <div className='bg-slate-100 rounded-lg p-6'>
        <h3 className='text-sm font-semibold'>
          Your Public link
        </h3>
        <div className='text-xs text-slate-500 py-2 flex justify-between'>
          <span>
            Share this link to let your clients pay you with ease.
          </span>
          <span>
            <PrimaryButton disabled={LinkwithPasscode == '' ? false : true} onClick={GenetateLink} size={'sm'} label={'Generate Link with passcode'} loading={Loading} />
          </span>
        </div>
        <div className='p-4 relative bg-white rounded-lg mt-2 flex items-center justify-between font-mono overflow-x-scroll'>
          <span>
            <span className="text-primary-500 select-none">{process.env.REACT_APP_CLIENT_URL}/</span>{`${Auth.data.username}`}
            {LinkwithPasscode}
          </span>
          <span className='text-primary cursor-pointer stroke-4 bg-white pl-4' onClick={() => copyToClipboard('Profile Link', `${process.env.REACT_APP_CLIENT_URL}/${Auth.data.username}${LinkwithPasscode}`)}>
            <BsCopy className='stroke-4' />
          </span>
        </div>
      </div>

    </div>
  )
}

export default Settings