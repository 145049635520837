import React, { useState, useEffect } from 'react';

const Typewriter = ({ words, typingDelay, pauseDelay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);

    useEffect(() => {
        if (currentWordIndex < words.length) {
            if (currentCharIndex < words[currentWordIndex].length) {
                // Typing the next character
                const timeout = setTimeout(() => {
                    setCurrentText(prevText => prevText + words[currentWordIndex][currentCharIndex]);
                    setCurrentCharIndex(prevIndex => prevIndex + 1);
                }, typingDelay);

                return () => clearTimeout(timeout);
            } else {
                // Delay after finishing the current word
                const timeout = setTimeout(() => {
                    setCurrentText(''); // Clear current text
                    setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length); // Move to the next word
                    setCurrentCharIndex(0); // Reset character index for the new word
                }, currentText == 'sheikhtabarak' ?4000:pauseDelay); // Delay before starting the next word

                return () => clearTimeout(timeout);
            }
        }
    }, [currentWordIndex, currentCharIndex, typingDelay, pauseDelay, words]);

    return <span className={`${currentText == 'sheikhtabarak' && 'text-primary font-semibold'}`}>{currentText}</span>;
};

export default Typewriter;