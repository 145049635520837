import React from 'react';
import { CiTextAlignLeft } from "react-icons/ci";



const PrimarySelectBox = ({ icon, size, isRequired, options, onOptionChange, label, selectedIndex, disable }) => {

    const [isOptionSelected, setIsOptionSelected] = React.useState(false);

    const changeTextColor = () => {
        setIsOptionSelected(true);
    };



    return (
        <div className="w-full my-2">

            <div className="relative z-20 bg-transparent dark:bg-form-input">
                <div className='text-primary-200 focus-within:text-primary-500 stroke-primary-200 stroke-2 focus-within:stroke-primary-500'>
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none  ">
                        {icon ? icon : <CiTextAlignLeft />}
                    </div>
                    <select
                        disabled={disable ? true : false}
                        value={selectedIndex}
                        onChange={onOptionChange}
                        className={`block w-full text-sm cursor-pointer appearance-none font-normal shadow-xs text-slate-900 bg-transparent border border-slate-200 focus-within:border-slate-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed  ${size == 'sm' ? 'py-3 pl-10 pr-4' : 'py-5 pl-10 pr-4'}`}
                    // className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-themeblack dark:focus:border-primary ${isOptionSelected ? 'text-black dark:text-white' : ''
                    // }`}
                    >

                        {
                            options && options.map((option, key) => {
                                return <option key={key} value={option.id} className="text-body dark:text-bodydark hover:bg-primary">
                                    {option.name}
                                </option>;
                            })
                        }

                    </select>
                </div>
                <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                    <svg
                        className="fill-current"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g opacity="0.8">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill=""
                            ></path>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
    )
}

export default PrimarySelectBox