import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom';
import MyLink from '../pages/Public Pages/mylink'
import axios from 'axios';
import Loader from '../components/Public/Widgets/loader';
import PasswordScreen from '../pages/Public Pages/password';
import { toast } from 'react-toastify';
import NoUserFound from '../pages/Public Pages/nouser';

const LockedPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [auth, setAuth] = useState({
        success: false,
        message: ''
    })
    const [PublicUser, setPublicUser] = useState({})
    const [Refresh, setRefresh] = useState(false)
    const [isUserExists, setisUserExists] = useState(true)
    const { username } = useParams();
    const [loading, setLoading] = useState(true)
    const pincode = searchParams.get('passcode')
    const [WrongPINEntered, setWrongPINEntered] = useState(false)

    useEffect(() => {
        console.log(PublicUser)
    }, [PublicUser])

    useEffect(() => {

        const getUserData = async () => {
            try {
                const UserData = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).get(`/api/auth/public/${username.toLowerCase()}${pincode !== null ? ('?pin=' + pincode) : ''}`);
                console.log(UserData)
                if (UserData.data.userExists == true) {
                    setisUserExists(true)
                }
                else {
                    setisUserExists(false)
                }

                if (UserData.data.success == true) {
                    if (UserData.data.auth == true) {

                        setAuth({
                            success: true,
                            message: UserData.data.message
                        })
                        setPublicUser(UserData.data)
                    }
                    else {
                        setAuth(
                            {
                                success: UserData.data.auth,
                                message: UserData.data.message
                            }
                        )

                        setWrongPINEntered(!WrongPINEntered)
                    }
                }

                toast.dismiss()
                if (UserData.data.auth == true) {
                    toast.success(UserData.data.message)
                }
                else if (UserData.data.auth == false) {
                    toast.error(UserData.data.message)
                }
                else if (UserData.data.auth == null) {
                    toast.info(UserData.data.message)
                }
                setLoading(false)
            } catch (error) {

                setLoading(false)
                setisUserExists(false)
            }
        }
        getUserData()

    }, [Refresh])

    return (
        loading ? (
            <div className='flex h-screen'>
                <Loader />
            </div>
        ) : isUserExists == true ? (

            auth.success == true ? (

                <MyLink PublicUser={PublicUser} />
            ) : (<PasswordScreen wrong={WrongPINEntered} loading={loading} value={pincode} setSearchParams={setSearchParams} setRefresh={setRefresh} Refresh={Refresh} />)
        )
            : (
                <div className='flex h-screen items-center justify-center'>
                    <NoUserFound />
                </div>
            )
    )
}

export default LockedPage