import React from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { MdDeleteOutline } from 'react-icons/md'
import { BsQuestionCircle } from 'react-icons/bs'
import { useState } from 'react'
import PrimaryButton from './button.primary'

const PopupBox = ({ isOpen, Loading, title, message, confirmButtonText, icon, onConfirmClick, onCancel, isthirdButton, thirdButtonLabel, onthirdButtonClick, close }) => {

    const [inProgress, setInProgress] = useState(false)

    return (
        <>
            <Dialog
                open={isOpen ? isOpen : false}
                onClose={close}
                as="div" className="relative z-9999 focus:outline-none" >

                <MdDeleteOutline />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">


                    <div className="flex min-h-full items-center justify-center text-center p-4">


                        <DialogPanel
                            transition
                            className="w-full max-w-md rounded-xl bg-white text-black shadow-[0px_0px_8px_-5px] pt-8 px-8 pb-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                        >
                            <div className='flex justify-center mb-4'>
                                <span className='bg-primary text-2xl text-white p-3 rounded-lg'>{icon ? icon : <BsQuestionCircle />}</span>
                            </div>
                            <DialogTitle className="font-bold text-lg">
                                {title ? title : 'Confirm'}
                            </DialogTitle>
                            <p dangerouslySetInnerHTML={{ __html: message }} className="mt-2 text-sm/6 " />
                            <div className="mt-2 flex gap-4  text-black">

                                <PrimaryButton onClick={onCancel} isenable={!Loading} label={'Cancel'} color={'text-black '} style={'bg-[#C7C8C6] rounded-lg'} />
                                <PrimaryButton loading={Loading} onClick={onConfirmClick} disabled={inProgress} label={confirmButtonText ? confirmButtonText : 'Done'} className={'bg-red-600 hover:bg-red-500 rounded-lg '} />
                            </div>
                            {isthirdButton && <PrimaryButton onClick={onthirdButtonClick} disabled={inProgress} label={thirdButtonLabel ? thirdButtonLabel : 'Confirm'} className={'bg-[#C7C8C6] rounded-lg '} />}
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PopupBox