import React, { useContext, useEffect, useState } from 'react'
import PrimarySelectBox from '../../../components/Public/Widgets/select.widget'
import PrimaryInput from '../../../components/Public/Widgets/input.primary'
import { FaShare } from 'react-icons/fa6'
import { BsLink45Deg } from 'react-icons/bs'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import SocialCard from './SocialCard'
import PrimaryButton from '../../../components/Public/Widgets/button.primary'
import GetDataAPIContainer from '../../../layouts/api/get.container'
import { scrollToSection } from '../../../utils/functions'
import { PostDataAPIFunction } from '../../../layouts/api/post.function'
import { AuthContext } from '../../../utils/authContext'
import { PutDataAPIFunction } from '../../../layouts/api/put.function'
import DeleteDataAPIContainer from '../../../layouts/api/delete.container'

const Socials = ({ refreshIframe }) => {

  const { Auth } = useContext(AuthContext)
  const [isNewMode, setisNewMode] = useState(true)
  const [DefinedPlatforms, setDefinedPlatforms] = useState('')
  const [isCustomPlatform, setisCustomPlatform] = useState(true)
  const [Refresh, setRefresh] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [socialLinks, setsocialLinks] = useState([])



  useEffect(() => {
    refreshIframe()
  }, [Refresh])

  const [DeleteData, setDeleteData] = useState({
    data: '',
    callfor: 'social-links',
    status: false,
    title: 'Are you sure you want to delete this Social Link?',
    message: 'Your are deleting a social link from database and we are informing you that this action cannot be undone.',
    successMessage: 'Social Link has been Deleted Successfully'
  })


  const deleteData = (data) => {
    setDeleteData({
      data: data,
      callfor: 'social-links',
      status: true,
      title: 'Do you want to delete this Social Link?',
      message: 'Your are deleting a social link from database and we are informing you that this action cannot be undone.',
      successMessage: 'Social Link has been Deleted Successfully'
    })

  }

  const [currentSocialMediaPlatform, setcurrentSocialMediaPlatform] = useState({
    platform: '',
    username: '',
    url: '',
  })

  const createSocialLink = async () => {
    setLoading(true);
    try {
      const APIResponse = await PostDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          platform: currentSocialMediaPlatform.platform,
          username: currentSocialMediaPlatform.username,
          url: currentSocialMediaPlatform.url
        },
        callfor: 'social-links',
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setisNewMode(true)
        setcurrentSocialMediaPlatform({
          platform: '',
          username: '',
          url: '',
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateSocialLink = async () => {
    setLoading(true);
    try {
      const APIResponse = await PutDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          platform: currentSocialMediaPlatform.platform,
          username: currentSocialMediaPlatform.username,
          url: currentSocialMediaPlatform.url
        },
        callfor: 'social-links/' + currentSocialMediaPlatform._id,
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setisNewMode(true)
        setcurrentSocialMediaPlatform({
          platform: '',
          username: '',
          url: '',
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {

    if (
      DefinedPlatforms === 'Facebook' ||
      DefinedPlatforms === 'Instagram' ||
      DefinedPlatforms === 'Twitter' ||
      DefinedPlatforms === 'TikTok' ||
      DefinedPlatforms === 'YouTube' ||
      DefinedPlatforms === 'Pinterest' ||
      DefinedPlatforms === 'LinkedIn' ||
      DefinedPlatforms === 'Reddit' ||
      DefinedPlatforms === 'Snapchat' ||
      DefinedPlatforms === 'WhatsApp'
    ) {
      setcurrentSocialMediaPlatform({
        ...currentSocialMediaPlatform,
        platform: DefinedPlatforms,
      })
      setisCustomPlatform(false)
    }
    else {
      setisCustomPlatform(true)
    }
  }, [DefinedPlatforms])


  const changeValue = (key, value) => {
    setcurrentSocialMediaPlatform({
      ...currentSocialMediaPlatform,
      [key]: value
    })
  }

  useEffect(() => {
    if (
      currentSocialMediaPlatform.platform !== 'Facebook' ||
      currentSocialMediaPlatform.platform !== 'Instagram' ||
      currentSocialMediaPlatform.platform !== 'Twitter' ||
      currentSocialMediaPlatform.platform !== 'TikTok' ||
      currentSocialMediaPlatform.platform !== 'YouTube' ||
      currentSocialMediaPlatform.platform !== 'Pinterest' ||
      currentSocialMediaPlatform.platform !== 'LinkedIn' ||
      currentSocialMediaPlatform.platform !== 'Reddit' ||
      currentSocialMediaPlatform.platform !== 'Snapchat' ||
      currentSocialMediaPlatform.platform !== 'WhatsApp'
    ) {

      console.log('I am called' + currentSocialMediaPlatform.platform)
      console.log(currentSocialMediaPlatform.platform !== 'WhatsApp')
      console.log(currentSocialMediaPlatform.platform !== 'Facebook')
      // setDefinedPlatforms('')
    }

    else {
      setisCustomPlatform(false)
      setDefinedPlatforms(currentSocialMediaPlatform)

    }
  }, [currentSocialMediaPlatform.platform])

  const Platforms = [
    { name: 'Others', id: '' },
    { name: 'Facebook', id: 'Facebook' },
    { name: 'Twitter', id: 'Twitter' },
    { name: 'Instagram', id: 'Instagram' },
    { name: 'LinkedIn', id: 'LinkedIn' },
    { name: 'Snapchat', id: 'Snapchat' },
    { name: 'TikTok', id: 'TikTok' },
    { name: 'Pinterest', id: 'Pinterest' },
    { name: 'Reddit', id: 'Reddit' },
    { name: 'YouTube', id: 'YouTube' },
    { name: 'WhatsApp', id: 'WhatsApp' }
  ];


  return (

    <div className='pt-4'>
      <div className='text-primary-500 font-semibold pb-4 text-lg'>Social Links</div>

      <div id='top' className='flex gap-4 w-full'>
        <div className='flex w-1/2 flex-grow items-center'>
          <PrimarySelectBox size={'sm'} isRequired={true} onOptionChange={(e) => {
            setDefinedPlatforms(e.target.value)
          }
          } selectedIndex={DefinedPlatforms} label={'Popular Platforms'} options={Platforms} />
        </div>

        <div className='flex w-1/2 flex-grow'>
          <PrimaryInput disabled={!isCustomPlatform} value={currentSocialMediaPlatform.platform} onChange={(e) => changeValue('platform', e.target.value)} icon={<FaShare />} type={'text'} placeholder={'Enter Name of Platform'} label={'Social Platform'} name={'platform'} size={'sm'} />
        </div>
      </div>

      <PrimaryInput value={currentSocialMediaPlatform.username} onChange={(e) => changeValue('username', e.target.value)} icon={<MdOutlineAlternateEmail />} type={'text'} placeholder={'Enter Username'} label={'Username'} name={'username'} size={'sm'} />
      <PrimaryInput value={currentSocialMediaPlatform.url} onChange={(e) => changeValue('url', e.target.value)} icon={<BsLink45Deg />} type={'text'} placeholder={'Link to Profile'} label={'Url'} name={'url'} size={'sm'} />

      <div className='pb-4 flex justify-end'>
        <div>
          {isNewMode ?
            <PrimaryButton onClick={createSocialLink} loading={Loading} size={'base'} label={'Create a new Social Link'} /> :
            <div className='flex gap-4'>
              <PrimaryButton
                onClick={() => {
                  setisNewMode(true)
                  setcurrentSocialMediaPlatform({
                    platform: '',
                    url: '',
                    username: '',
                  })
                }} size={'base'} className={'bg-red-600 hover:bg-red-500'} label={'Cancel'} />
              <PrimaryButton onClick={updateSocialLink} loading={Loading} size={'base'} className={'bg-blue-700 hover:bg-blue-600'} label={'Update'} />
            </div>
          }
        </div>
      </div>
      <div className='h-full'>
        <GetDataAPIContainer RefreshData={Refresh} callfor={'social-links'} SingleId={''} ScreenName={'Social Links'} data={socialLinks} setData={setsocialLinks}>
          <DeleteDataAPIContainer RefreshData={Refresh} setRefreshData={setRefresh} DeleteData={DeleteData} setDeleteData={setDeleteData} >
            {socialLinks&&socialLinks.map((link) => (<SocialCard
              onDelete={() => deleteData(link._id)}
              isEditable={true}
              onEdit={() => {
                setcurrentSocialMediaPlatform(link)
                setDefinedPlatforms(link.platform)
                scrollToSection('top')
                setisNewMode(false)
              }}
              socialLink={link} />))}
          </DeleteDataAPIContainer>
        </GetDataAPIContainer>

      </div>
    </div>
  )
}

export default Socials