import React, { useEffect, useState } from 'react'

const ToogleButton = ({ value, onToogle }) => {

    const [localValue, setLocalValue] = useState(value)
    useEffect(() => {
        if (value == false) {
            setLocalValue(false)
        }
        else {
            setLocalValue(true)
        }
    }, [value])

    useEffect(() => {
        const ToogleButton = document.getElementById('toogle-button');

        if (localValue == false) {
            ToogleButton.classList.remove('translate-x-6')
            ToogleButton.classList.add('translate-x-2')

        }
        else {
            ToogleButton.classList.remove('translate-x-2')
            ToogleButton.classList.add('translate-x-6')
        }
    }, [localValue])

    return (
        <div>
            <div onClick={onToogle} className={`rounded-full w-14 h-8 flex cursor-pointer items-center duration-500 relative shadow-inner  ${localValue == true ? 'bg-green-100 ' : 'bg-slate-200'}`}>
                <div id='toogle-button' className={`absolute translate-x-2 h-6 w-6 rounded-full duration-500 shadow-lg ${localValue == true ? 'bg-primary-500' : 'bg-slate-400'} `}></div>
            </div>
        </div>
    )
}

export default ToogleButton