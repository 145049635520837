// src/Login.js
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PrimaryButton from '../../../components/Public/Widgets/button.primary';
import PrimaryInput from '../../../components/Public/Widgets/input.primary';
import { AuthContext } from '../../../utils/authContext';
import { toast } from 'react-toastify'
import { PostDataAPIFunction } from '../../../layouts/api/post.function';
import { throttle } from '../../../utils/functions';
import { MdPassword } from 'react-icons/md';

const SetNewPassword = () => {

    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams();
    const resetpasswordtoken = searchParams.get('resetpasswordtoken');

    const [rotate, setRotate] = useState({});

    const onMouseMove = useCallback(
        (id) =>
            throttle((e) => {
                const card = e.currentTarget;
                const box = card.getBoundingClientRect();
                const x = e.clientX - box.left;
                const y = e.clientY - box.top;
                const centerX = box.width / 2;
                const centerY = box.height / 2;
                const rotateX = (y - centerY) / 7;
                const rotateY = (centerX - x) / 7;


                setRotate((prev) => ({
                    ...prev,
                    [id]: { x: rotateX, y: rotateY },
                }));
            }, 100),
        []
    );

    const onMouseLeave = (id) => {
        setRotate((prev) => ({
            ...prev,
            [id]: { x: 0, y: 0 },
        }));
    };

    const [Password, setPassword] = useState({
        first: '',
        confirm: ''
    })

    const { login, refreshAuth } = useContext(AuthContext)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (Password.first == Password.confirm) {

            setLoading(true);

            try {
                const APIResponse = await PostDataAPIFunction({
                    Recieveddata: {
                        password: Password.first,
                        token: resetpasswordtoken
                    },
                    callfor: 'auth/forget/verfiy-and-set-new-password',
                })

                if (APIResponse.success && APIResponse.data.status === 200) {
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/login')
                    }, 2000);
                }

                else {
                    setLoading(false);
                }

            } catch (error) {
                console.error(error);
                setLoading(false);
            }

        }
        else {
            toast.error('Passwords Mismatch')
        }
    };


    // const verfiyToken = async () => {

    // }

    useEffect(() => {

        if (resetpasswordtoken) {

            // verfiyToken()
            // '/auth/verify/'

        }
        else {
            navigate('/forget-password/send-verfication-link')
        }

    }, [])

    return (
        <div className=" flex flex-col-reverse lg:flex-row items-center justify-center h-screen">

            <div className="flex flex-col justify-center h-screen no-scrollbar overflow-scroll lg:w-1/2 px-12 lg:px-28 bg-white  rounded-lg">

                <Link to={'/'} >
                    <img className='h-16 mb-8' src="/logo-light.png" alt="" />
                </Link>

                <h2 className="text-4xl font-bold">Set New Password</h2>
                <h3 className='mb-6 text-slate-500 py-4'>
                    Choose a new Password for your account
                </h3>
                <form onSubmit={handleSubmit}>

                    {/* <PrimaryInput
                        label={'Email/username'} type={'text'} name={'emailOrUsername'} placeholder={'Email or Username'}
                        onChange={(e) => setemailOrUsername(e.target.value)} value={emailOrUsername}
                        icon={
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=" stroke-current ml-1">
                                <path d="M1.87651 3.83325L6.79885 7.07932C8.55702 8.13423 9.43612 8.66169 10.402 8.6387C11.3678 8.61572 12.2208 8.04705 13.9268 6.90971L18.1232 3.83325M8.33317 14.6666H11.6665C14.8092 14.6666 16.3805 14.6666 17.3569 13.6903C18.3332 12.714 18.3332 11.1426 18.3332 7.99992C18.3332 4.85722 18.3332 3.28587 17.3569 2.30956C16.3805 1.33325 14.8092 1.33325 11.6665 1.33325H8.33317C5.19047 1.33325 3.61913 1.33325 2.64281 2.30956C1.6665 3.28587 1.6665 4.85722 1.6665 7.99992C1.6665 11.1426 1.6665 12.714 2.64281 13.6903C3.61913 14.6666 5.19047 14.6666 8.33317 14.6666Z" stroke="" stroke-width="1.5" stroke-linecap="round"></path>
                            </svg>
                        } /> */}

                    <PrimaryInput
                        label={'Password'} type={'text'} name={'password'} placeholder={'Enter Password'}
                        onChange={(e) => setPassword({ ...Password, first: e.target.value })} value={Password.first}
                        icon={
                            <MdPassword />
                        } />

                    <PrimaryInput
                        label={'Confirm Password'} type={'text'} name={'confirm-password'} placeholder={'Confirm Password'}
                        onChange={(e) => setPassword({ ...Password, confirm: e.target.value })} value={Password.confirm}
                        icon={
                            <MdPassword />
                        } />

                    <PrimaryButton
                        size={'base'} loading={loading} label="Reset" type="submit" />


                    <div className='pt-6'>
                        <div>Remembered Password? <Link to={'/login'} className='text-primary-500'>Login</Link></div>
                    </div>


                </form>
            </div>

            <div

                className='lg:w-1/2 bg-background-500 rounded-l-xl absolute lg:relative flex items-center -z-10 lg:z-0 h-full'

            >
                <img
                    className='cursor-pointer'
                    style={{
                        transform: `rotateX(${rotate['user-friendly-dashboard-img']?.x || 0}deg) rotateY(${rotate['user-friendly-dashboard-img']?.y || 0}deg)`,
                        transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
                        transitionDuration: '1000ms'
                    }}
                    onMouseMove={onMouseMove('user-friendly-dashboard-img')}
                    onMouseLeave={() => onMouseLeave('user-friendly-dashboard-img')}
                    src='/user-friendly-dashboard.png' alt="" />
            </div>

        </div>
    );
};

export default SetNewPassword;