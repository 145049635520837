import React, { useState, useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/Public/Widgets/button.primary'
import PrimaryInput from '../../components/Public/Widgets/input.primary'
import { CiUser } from 'react-icons/ci'
import { BsCheck2Circle, BsCheckCircleFill } from 'react-icons/bs'
import Typewriter from '../../components/Public/Widgets/typewriter.animation'
import { MdLock } from 'react-icons/md'
import { Link } from 'react-scroll';
import { throttle } from '../../utils/functions'

const Home = () => {

    const [rotate, setRotate] = useState({});

    const onMouseMove = useCallback(
        (id) =>
            throttle((e) => {
                const card = e.currentTarget;
                const box = card.getBoundingClientRect();
                const x = e.clientX - box.left;
                const y = e.clientY - box.top;
                const centerX = box.width / 2;
                const centerY = box.height / 2;
                const rotateX = (y - centerY) / 7;
                const rotateY = (centerX - x) / 7;


                setRotate((prev) => ({
                    ...prev,
                    [id]: { x: rotateX, y: rotateY },
                }));
            }, 100),
        []
    );

    const onMouseLeave = (id) => {
        setRotate((prev) => ({
            ...prev,
            [id]: { x: 0, y: 0 },
        }));
    };

    const [username, setUsername] = useState()

    const DummyUsernames = ["sheikhtabarak", "amina", "ahmad", "usman", "fatima", "haroon"]
    const DummyPIN = ["1234", "0000", "0909", "8888"]
    const AdminLinks = ["payments", "social", "account"]

    return (
        <div className='flex flex-col items-center justify-center h-full w-full '>

            <section id='hero' className='px-8 w-full text-center py-8 bg-background-500'>
                <div className='flex justify-center px-4 lg:px-48'>
                    <div>
                        <div>
                            <span className='text-background-500 bg-primary-500 rounded-full px-6 py-2 text-[10px] lg:text-xs'>Let Your Clients Know How to Pay You</span>
                        </div>
                        <h4 className="text-2xl lg:text-6xl text-background-100 font-semibold pt-8 lg:leading-[75px]">
                            <span>One Public Link for All Your Payment Methods</span>
                        </h4>
                        <p className='text-sm lg:text-base  py-4 text-slate-400'>
                            No More Hassle of Sharing Details Every Time! Claim your personalized public link with Go Pay Here to consolidate all your payment methods in one place—just share it for easy and secure transactions!
                        </p>

                        <div className='flex flex-col lg:flex-row gap-4 pt-4 lg:px-32 items-center w-full justify-center'>
                            <div className='lg:w-3/5 w-full relative dark'>
                                <PrimaryInput value={username} onChange={(e) => { setUsername(e.target.value) }} icon={<CiUser />} label={process.env.REACT_APP_CLIENT_URL.replace(/^https?:\/\//, '') + '/'} placeholder={'yourname'} />
                            </div>
                            <div className='lg:w-2/5  w-full h-16'>
                                <NavLink to={'/register?username=' + username}>
                                    <PrimaryButton size={'lg'} style={'border-[1px] !font-semibold border-primary'} color={'text-primary'} isenable={true} label={'Claim your username'} />
                                </NavLink>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="flex justify-end lg:w-1/2">
                    <img className='h-full lg:h-[470px]' src="/hero-image.png" alt="" />
                </div> */}
            </section>

            <section id='personized-link' className='py-4'>

                <div className='flex flex-col-reverse lg:flex-row px-8 w-full justify-between py-12'>
                    <div className='flex lg:w-1/2 pt-6 lg:px-6'>
                        <div>
                            <div>
                                <span className='text-green-800 bg-green-100 rounded-full px-6 py-2 text-[10px] lg:text-xs'>Your Link, Your Name</span>
                            </div>
                            <h4 className="text-2xl lg:text-4xl text-backgound-500 font-semibold pt-6">
                                <span>Personalized Payment Link</span>
                            </h4>
                            <p className='text-base text-slate-500 lg:text-base pt-4'>
                                Claim your unique public link that consolidates all your payment methods in one easy-to-share URL.
                            </p>
                            <ul className='flex flex-col gap-4 text-slate-500 py-6'>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Simplify the payment process for clients.
                                    </p>
                                </li>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Enhances professionalism with a custom link.

                                    </p>
                                </li>
                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Easily shareable through various platforms.
                                    </p>
                                </li>
                            </ul>


                            <div className='w-full lg:pr-60'>
                                <Link smooth duration={1000} to={'hero'}> <PrimaryButton style={'bg-primary'} isenable={true} label={'Get Your Link Now!'} /></Link>
                            </div>
                        </div>

                    </div>
                    <div
                        key={'personized-link-img'}
                        className="card relative lg:w-1/2 h-75 lg:h-full cursor-pointer rounded-xl bg-white lg:transition-[all_400ms_cubic-bezier(0.99,0.88,0.18,0.89)_1000s] will-change-transform"
                        onMouseMove={onMouseMove('personized-link-img')}
                        onMouseLeave={() => onMouseLeave('personized-link-img')}
                        style={{
                            transform: `rotateX(${rotate['personized-link-img']?.x || 0}deg) rotateY(${rotate['personized-link-img']?.y || 0}deg)`,
                            transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
                            transitionDuration: '1000ms'
                        }} >

                        <NavLink target='_blank' to={'/sheikhtabarak'} className='absolute -top-4 lg:top-24 left-0'>

                            <div className='bg-white shadow-md border-[1px] border-slate-200 rounded-full px-4 py-2 text-xs flex gap-2 items-center'>
                                <MdLock className='text-primary-500 ' />
                                <div className='font-semibold select-none'>{process.env.REACT_APP_CLIENT_URL.replace(/^https?:\/\//, '')}/<span className='font-normal'><Typewriter words={DummyUsernames} typingDelay={100} pauseDelay={1000} /></span></div>
                            </div>

                        </NavLink>

                        <img className='h-full lg:h-[470px]' src="/personized-link.png" alt="" />
                    </div>
                </div>
            </section>

            <section id='secure-link' className='bg-background-500 py-4'>
                <div className='flex  flex-col lg:flex-row px-8 w-full items-center justify-between py-12'>
                    <div
                        key={'secure-link-img'}
                        className="card relative lg:w-1/2  h-75 lg:h-full cursor-pointer rounded-xl pr-12 lg:transition-[all_400ms_cubic-bezier(0.99,0.88,0.18,0.89)_1000s] will-change-transform"
                        onMouseMove={onMouseMove('secure-link-img')}
                        onMouseLeave={() => onMouseLeave('secure-link-img')}
                        style={{
                            transform: `rotateX(${rotate['secure-link-img']?.x || 0}deg) rotateY(${rotate['secure-link-img']?.y || 0}deg)`,
                            transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
                            transitionDuration: '1000ms'
                        }} >
                        <div className='absolute -top-4 lg:top-12 left-0'>

                            <div className='bg-white shadow-md border-[1px] border-slate-200 rounded-full px-4 py-2 text-xs flex gap-2 items-center'>
                                <MdLock className='text-primary-500 ' />
                                <div className='font-semibold select-none'>?passcode=<span className='font-normal'><Typewriter words={DummyPIN} typingDelay={100} pauseDelay={1000} /></span></div>
                            </div>

                        </div>
                        <img className='h-full ' src="/secure-link.png" alt="" />
                    </div>
                    <div className='flex lg:w-1/2 pt-6 lg:px-6'>
                        <div>
                            <div>
                                <span className='text-green-800 bg-green-100 rounded-full px-6 py-2 text-[10px] lg:text-xs'>We care about your privacy</span>
                            </div>
                            <h4 className="text-2xl lg:text-4xl text-slate-200 font-semibold pt-6">
                                <span> Privacy Controls</span>
                            </h4>
                            <p className='text-base text-slate-300 lg:text-base pt-4'>
                                Choose to keep your link public or password-protect it with a 4-digit PIN for added security
                            </p>
                            <ul className='flex flex-col gap-4 text-slate-300 py-6'>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Protects your sensitive information.
                                    </p>
                                </li>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Gives you control over who can access your payment details

                                    </p>
                                </li>
                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Hassle-free link sharing including PIN
                                    </p>
                                </li>
                            </ul>


                            <div className='w-full lg:pr-60'>
                                <Link smooth duration={1000} to={'hero'}> <PrimaryButton style={'bg-primary'} isenable={true} label={'Secure your link now'} /></Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id='user-friendly-dashboard' className='py-4'>
                <div className='flex flex-col-reverse lg:flex-row px-8 w-full justify-between py-12'>
                    <div className='flex lg:w-1/2 pt-6 lg:px-6'>
                        <div>
                            <div>
                                <span className='text-green-800 bg-green-100 rounded-full px-6 py-2 text-[10px] lg:text-xs'>Everything You Need in One Place</span>
                            </div>
                            <h4 className="text-2xl lg:text-4xl text-backgound-500 font-semibold pt-6">
                                <span>User-Friendly Dashboard</span>
                            </h4>
                            <p className='text-base text-slate-500 lg:text-base pt-4'>
                                Manage all your payment options, social links, and profile information from a centralized dashboard, designed for ease of use.                            </p>
                            <ul className='flex flex-col gap-4 text-slate-500 py-6'>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Intuitive layout for quick navigation.
                                    </p>
                                </li>

                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Simplifies the management of all your details in one place.
                                    </p>
                                </li>
                                <li className="flex items-start lg:col-span-1">
                                    <div className="flex-shrink-0">
                                        <BsCheckCircleFill className='text-primary-500' />
                                    </div>
                                    <p className="ml-3 leading-5 text-gray-600">
                                        Highly Mobile responsive for pocket access
                                    </p>
                                </li>
                            </ul>


                            <div className='w-full lg:pr-60'>
                                <Link smooth duration={1000} to={'hero'}> <PrimaryButton style={'bg-primary'} isenable={true} label={'Visit your dashboard'} /></Link>
                            </div>
                        </div>

                    </div>
                    <div
                        key={'user-friendly-dashboard-img'}
                        className="card relative lg:w-1/2  h-75 lg:h-full cursor-pointer rounded-xl bg-white lg:transition-[all_400ms_cubic-bezier(0.99,0.88,0.18,0.89)_1000s] will-change-transform"
                        onMouseMove={onMouseMove('user-friendly-dashboard-img')}
                        onMouseLeave={() => onMouseLeave('user-friendly-dashboard-img')}
                        style={{
                            transform: `rotateX(${rotate['user-friendly-dashboard-img']?.x || 0}deg) rotateY(${rotate['user-friendly-dashboard-img']?.y || 0}deg)`,
                            transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
                            transitionDuration: '1000ms'
                        }} >
                        <div className='absolute -top-4 left-0'>

                            <div className='bg-white shadow-md border-[1px] border-slate-200 rounded-full px-4 py-2 text-xs flex gap-2 items-center'>
                                <MdLock className='text-primary-500 ' />
                                <div className='font-semibold select-none'>{process.env.REACT_APP_CLIENT_URL.replace(/^https?:\/\//, '')}/admin/<span className='font-normal'><Typewriter words={AdminLinks} typingDelay={100} pauseDelay={1000} /></span></div>
                            </div>

                        </div>
                        <img className='h-full' src="/user-friendly-dashboard.png" alt="" />
                    </div>
                </div>
            </section>

        </div >

    )
}

export default Home