import React, { useState } from 'react';
import { BsCircle, BsCircleFill, BsCopy } from 'react-icons/bs';
import { toast } from "react-toastify";
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { copyToClipboard } from '../../../utils/functions';

const PaymentCard = ({ PaymentMethod, onEdit, onDelete, isEditable }) => {

    const [isopen, setisOpen] = useState(false)

    return (
        // <div className="border-[1px] border-background-100 rounded-lg mb-4 w-full h-content relative">
           <div className="border-[1px] border-background-100 rounded-lg mb-4 w-full h-content relative">

            <div className={`flex gap-4 items-center p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition shadow-xl rounded-lg overflow-hidden`}>
                {PaymentMethod.website && <div className='rounded-md w-12 h-12'>

                    <img onClick={() => { setisOpen(!isopen) }} className='w-full h-full rounded-md object-cover' src={'https://logo.clearbit.com/' + PaymentMethod.website} alt="" />

                </div>}
                <div className='flex justify-between w-full items-center '>
                    <div className='w-full' onClick={() => { setisOpen(!isopen) }}>
                        <div>
                            {PaymentMethod ? (PaymentMethod.bankName ? PaymentMethod.bankName : PaymentMethod.walletId ? PaymentMethod.walletId : 'Bank Name') : 'Bank Name'}
                        </div>


                        <div className='text-xs text-slate-400 flex text-wrap'>
                            {PaymentMethod ? (PaymentMethod.accountNumber ? PaymentMethod.accountNumber : PaymentMethod.cryptocurrencyAddress ? PaymentMethod.cryptocurrencyAddress : 'Account number') : 'Account number'}
                        </div>
                    </div>
                    {isEditable && isEditable == true && <div>
                        <span className='flex gap-4 text-lg items-center  absolute right-0 top-4 px-2 bg-white'>
                            <div><BsCircleFill className={`${PaymentMethod.is_active == true ? 'text-primary-500' : 'text-red-500'} text-sm`} /></div>
                            <div onClick={onEdit} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-primary-500 hover:scale-95 duration-300 rounded-lg p-3' >
                                <CiEdit />
                            </div>

                            <div onClick={onDelete} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-red-500 hover:scale-95 duration-300 rounded-lg p-3'>
                                <MdDelete />
                            </div>
                        </span>
                    </div>}
                </div>
            </div >

            <div
                className={`no-scrollbar overflow-y-scroll transition-max-height duration-500 ease-in-out ${isopen ? 'max-h-full' : 'max-h-0'
                    }`}
            >
                <div className="p-4 bg-white rounded-lg ">

                    {PaymentMethod && PaymentMethod.details && <div className=' text-slate-500 text-sm pb-4'>
                        {PaymentMethod.details}
                    </div>}


                    {PaymentMethod && (PaymentMethod.bankName || PaymentMethod.accountNumber || PaymentMethod.branchCode || PaymentMethod.swiftCode || PaymentMethod.iban) && <div className='text-xs uppercase font-semibold text-primary-500 py-2'>Bank Details</div>}

                    {PaymentMethod && PaymentMethod.bankName && <div className='pb-2'>
                        {PaymentMethod.bankName}
                    </div>}

                    {PaymentMethod && PaymentMethod.accountTitle && <blockquote className=" p-2 text-slate-800 relative h-13 border-l-4 border-primary-500 bg-slate-50 flex items-end justify-between mb-2" id="accountTitle">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Account Title</div>

                        <div>
                            {PaymentMethod.accountTitle}
                        </div>
                        {/* <BsCopy onClick={() => copyToClipboard('Account Number', PaymentMethod.accountNumber)} className='cursor-pointer' /> */}
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.accountNumber && <blockquote className="p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Account Number</div>

                        <div>
                            {PaymentMethod.accountNumber}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Account Number', PaymentMethod.accountNumber)} className='cursor-pointer ' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.branchCode && <blockquote className=" p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between  mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Branch Code</div>

                        <div>
                            {PaymentMethod.branchCode}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Brand code', PaymentMethod.branchCode)} className='cursor-pointer' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.swiftCode && <blockquote className=" p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Swift Code</div>

                        <div>
                            {PaymentMethod.swiftCode}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>

                            <BsCopy onClick={() => copyToClipboard('Swift Code', PaymentMethod.swiftCode)} className='cursor-pointer' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.iban && <blockquote className=" p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>IBAN</div>

                        <div>
                            {PaymentMethod.iban}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('IBAN', PaymentMethod.accountNumber)} className='' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && (PaymentMethod.cryptocurrencyAddress || PaymentMethod.walletId) && <div className='text-xs uppercase font-semibold text-primary-500 py-2'>Crypto Address</div>}

                    {PaymentMethod && PaymentMethod.cryptocurrencyAddress && <blockquote className=" p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Crypto Address</div>

                        <div className='no-scrollbar overflow-x-scroll'>
                            {PaymentMethod.cryptocurrencyAddress}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Crypto Address', PaymentMethod.cryptocurrencyAddress)} className='cursor-pointer' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.walletId && <blockquote className="p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Wallet Id</div>

                        <div>
                            {PaymentMethod.walletId}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Wallet Id', PaymentMethod.walletId)} className='cursor-pointer' />
                        </div>
                    </blockquote>}



                    {PaymentMethod && (PaymentMethod.mobileNumber || PaymentMethod.paymentEmail) && <div className='text-xs uppercase font-semibold text-primary-500 py-2'>Mobile/Email Payment</div>}

                    {PaymentMethod && PaymentMethod.mobileNumber && <blockquote className="p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Mobile Number</div>

                        <div>
                            {PaymentMethod.mobileNumber}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Mobile Number', PaymentMethod.mobileNumber)} className='cursor-pointer' />
                        </div>
                    </blockquote>}

                    {PaymentMethod && PaymentMethod.paymentEmail && <blockquote className="p-2 text-slate-700 relative h-13 border-l-4 border-primary-500 bg-slate-50 italic flex items-end justify-between mb-2" id="accountNumber">
                        <div className='text-[10px] absolute left-2 -top-1 text-slate-400 py-2'>Payment Email</div>

                        <div>
                            {PaymentMethod.paymentEmail}
                        </div>
                        <div className='cursor-pointer bg-slate-50 px-2 absolute right-0 top-2 bottom-0 h-full justify-center items-center flex'>
                            <BsCopy onClick={() => copyToClipboard('Payment Email', PaymentMethod.paymentEmail)} className='cursor-pointer' />
                        </div>
                    </blockquote>}

                </div>
            </div>

        </div >
    )
}

export default PaymentCard