// privateRoute.js
import { useContext, useEffect } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { AuthContext } from '../utils/authContext';
import Loader from '../components/Public/Widgets/loader';

const PrivateRoute = ({ children }) => {
    const { Auth, refreshAuth } = useContext(AuthContext);
    const location = useLocation();

    useEffect(() => {
        if (Auth.loading) {
        }
        else {
            refreshAuth(Auth.token)
        }
    }, [location.pathname])


    if (Auth.loading) {
        return <Loader />;
    }

    if (!Auth.isLoggedin) {
        return <Navigate to="/login" replace />;
    }

    else {
        console.log('User Loggedin and Got Access')
    }


    return children;
};

export default PrivateRoute;