import React, { useContext, useEffect, useState } from 'react'
import PrimaryButton from '../../../components/Public/Widgets/button.primary';
import PrimaryInput from '../../../components/Public/Widgets/input.primary';
import { AiFillBank } from 'react-icons/ai';
import PrimarySelectBox from '../../../components/Public/Widgets/select.widget';
import { MdCancel, MdEmail, MdNumbers } from 'react-icons/md';
import { BsCode, BsGlobe, BsLock, BsPass, BsPhone, BsPlus, BsWallet } from 'react-icons/bs';
import { FaCity, FaConnectdevelop } from 'react-icons/fa6';
import { CiLock, CiUser } from 'react-icons/ci';
import PaymentCard from './PaymentCard';
import ToogleButton from '../../../components/Public/Widgets/toogle.widget';
import GetDataAPIContainer from '../../../layouts/api/get.container';
import DeleteDataAPIContainer from '../../../layouts/api/delete.container';
import { PostDataAPIFunction } from '../../../layouts/api/post.function';
import { PutDataAPIFunction } from '../../../layouts/api/put.function';
import { AuthContext } from '../../../utils/authContext';

const PaymentMethod = ({ refreshIframe }) => {

  const { Auth } = useContext(AuthContext)
  const [PaymentMethods, setPaymentMethods] = useState([])
  const [Refresh, setRefresh] = useState(false)
  const [Loading, setLoading] = useState(false)

  useEffect(() => {
    refreshIframe()
  }, [Refresh])

  const [currentPaymentMethod, setcurrentPaymentMethod] = useState({
    type: '',
    website: '',
    accountNumber: '',
    branchCode: '',
    iban: '',
    swiftCode: '',
    bankName: '',
    paymentEmail: '',
    mobileNumber: '',
    walletId: '',
    cryptocurrencyAddress: '',
    details: '',
    is_active: true
  })

  const [CurrentMethod, setCurrentMethod] = useState({
    mode: 'none',
    isOpen: true,
  })

  const [DeleteData, setDeleteData] = useState({
    data: '',
    callfor: 'payment-methods',
    status: false,
    title: 'Are you sure you want to delete this Social Link?',
    message: 'Your are deleting a social link from database and we are informing you that this action cannot be undone.',
    successMessage: 'Social Link has been Deleted Successfully'
  })

  const scrollToSection = () => {
    const element = document.getElementById('top');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const clickedEdit = (paymentM) => {

    scrollToSection()
    setCurrentMethod({
      ...CurrentMethod,
      mode: 'edit',
      isOpen: true,
    })
    setcurrentPaymentMethod(paymentM)

  }

  const deletePaymentMethod = (data) => {
    setDeleteData({
      data: data,
      callfor: 'payment-methods',
      status: true,
      title: 'Want to delete this Payment Method?',
      message: 'Your are deleting a payment method from database and we are informing you that this action cannot be undone.',
      successMessage: 'Social Link has been Deleted Successfully'
    })

  }


  const createPaymentMethod = async () => {
    setLoading(true);
    try {
      const APIResponse = await PostDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          type: currentPaymentMethod.type,
          accountTitle: currentPaymentMethod.accountTitle,
          accountNumber: currentPaymentMethod.accountNumber,
          branchCode: currentPaymentMethod.branchCode,
          iban: currentPaymentMethod.iban,
          swiftCode: currentPaymentMethod.swiftCode,
          bankName: currentPaymentMethod.bankName,
          paymentEmail: currentPaymentMethod.paymentEmail,
          mobileNumber: currentPaymentMethod.mobileNumber,
          walletId: currentPaymentMethod.walletId,
          cryptocurrencyAddress: currentPaymentMethod.cryptocurrencyAddress,
          details: currentPaymentMethod.details,
          website: currentPaymentMethod.website,
          is_active: currentPaymentMethod.is_active
        },
        callfor: 'payment-methods',
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setCurrentMethod({
          mode: 'none',
          isOpen: false
        })
        setcurrentPaymentMethod({
          type: '',
          accountTitle: '',
          accountNumber: '',
          branchCode: '',
          iban: '',
          swiftCode: '',
          bankName: '',
          paymentEmail: '',
          mobileNumber: '',
          walletId: '',
          cryptocurrencyAddress: '',
          details: '',
          website: '',
          is_active: true
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updatePaymentMethod = async () => {
    setLoading(true);
    try {
      const APIResponse = await PutDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          type: currentPaymentMethod.type,
          accountTitle: currentPaymentMethod.accountTitle,
          accountNumber: currentPaymentMethod.accountNumber,
          branchCode: currentPaymentMethod.branchCode,
          iban: currentPaymentMethod.iban,
          swiftCode: currentPaymentMethod.swiftCode,
          bankName: currentPaymentMethod.bankName,
          paymentEmail: currentPaymentMethod.paymentEmail,
          mobileNumber: currentPaymentMethod.mobileNumber,
          walletId: currentPaymentMethod.walletId,
          cryptocurrencyAddress: currentPaymentMethod.cryptocurrencyAddress,
          details: currentPaymentMethod.details,
          website: currentPaymentMethod.website,
          is_active: currentPaymentMethod.is_active
        },
        callfor: 'payment-methods/' + currentPaymentMethod._id,
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setCurrentMethod({
          mode: 'none',
          isOpen: false
        })
        setcurrentPaymentMethod({
          type: '',
          accountNumber: '',
          accountTitle: '',
          branchCode: '',
          iban: '',
          swiftCode: '',
          bankName: '',
          paymentEmail: '',
          mobileNumber: '',
          walletId: '',
          cryptocurrencyAddress: '',
          details: '',
          is_active: true
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const changeValue = (key, value) => {
    setcurrentPaymentMethod({
      ...currentPaymentMethod,
      [key]: value
    })
  }


  useEffect(() => {
    console.log(currentPaymentMethod)
  }, [currentPaymentMethod])

  const toggleWidget = () => {
    if (CurrentMethod.isOpen == false) {
      setCurrentMethod({
        ...CurrentMethod,
        isOpen: true
      })
    }
    else {
      setCurrentMethod({
        ...CurrentMethod,
        isOpen: false
      })
    }
  };

  const methodTypes = [{ name: 'WALLET', id: 'WALLET' }, { name: 'CRYPTO', id: 'CRYPTO' }, { name: 'BANK', id: 'BANK' }, { name: 'MOBILE', id: 'MOBILE' }, { name: 'DIRECT DEBIT', id: 'DIRECT_DEBIT' }, { name: 'REMITTENCE', id: 'REMITTENCE' }, { name: 'OTHER', id: 'OTHER' }]

  return (
    <>
      <div id='top' className='pb-4 w-full'>
        <div className='text-primary-500 font-semibold pb-4 text-lg'>Payment Methods</div>

        <PrimaryButton
          icon={<BsPlus />}
          className={'border-2 border-primary-500 bg-transparent !text-primary-500 hover:!text-white'}
          onClick={() => {
            setcurrentPaymentMethod({
              is_active: true
            })
            setCurrentMethod({
              ...CurrentMethod,
              isOpen: true,
              mode: 'new',
            })
          }}
          size={'base'}
          label={'Add new Payment Method'} />
      </div>




      {(CurrentMethod.mode == 'edit' || CurrentMethod.mode == 'new') && <div className="border-[1px] border-background-100 rounded-lg mb-4">
        <div
          className={`flex gap-4 items-center p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition shadow-xl rounded-lg `}

        >
          {currentPaymentMethod.website && <div className='rounded-md w-12 h-12'>
            <img onClick={() => toggleWidget(true)} className='w-full h-full rounded-md object-cover' src={'https://logo.clearbit.com/' + currentPaymentMethod.website} alt="" />
          </div>}
          <div className='flex justify-between w-full items-center'>
            <div className='w-full' onClick={() => toggleWidget(true)} >
              <div>
                {currentPaymentMethod ? (currentPaymentMethod.bankName ? currentPaymentMethod.bankName : currentPaymentMethod.walletId ? currentPaymentMethod.walletId : 'Bank Name') : 'Bank Name'}
              </div>
              <div className='text-sm text-slate-400'>
                {currentPaymentMethod ? (currentPaymentMethod.accountNumber ? currentPaymentMethod.accountNumber : currentPaymentMethod.cryptocurrencyAddress ? currentPaymentMethod.cryptocurrencyAddress : 'Account number') : 'Account number'}
              </div>
            </div>
            <div>
              <div className="flex flex-col-reverse lg:flex-row gap-4 items-center">
                <ToogleButton onToogle={() => setcurrentPaymentMethod({
                  ...currentPaymentMethod,
                  is_active: !currentPaymentMethod.is_active
                })} value={currentPaymentMethod.is_active} />
                <div onClick={() => {
                  setCurrentMethod({
                    ...CurrentMethod,
                    isOpen: false,
                    mode: null,
                  })
                }} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-red-500 hover:scale-95 duration-300 rounded-lg p-3' >
                  <MdCancel />
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className={`no-scrollbar overflow-y-scroll transition-max-height duration-300 ease-in-out ${CurrentMethod.isOpen ? 'max-h-full' : 'max-h-0'}`}>
          <div className="p-4 bg-white rounded-lg">

            <PrimarySelectBox size={'sm'} isRequired={true} onOptionChange={(e) => { changeValue('type', e.target.value) }} selectedIndex={currentPaymentMethod.type} label={'Type'} options={methodTypes} />
            <PrimaryInput value={currentPaymentMethod.bankName} onChange={(e) => changeValue('bankName', e.target.value)} icon={<AiFillBank />} type={'text'} placeholder={'Enter Name of Bank'} label={'Bank Name'} name={'bankName'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.accountTitle} onChange={(e) => changeValue('accountTitle', e.target.value)} icon={<CiUser />} type={'text'} placeholder={'Enter Account Title'} label={'Account Title'} name={'accountTitle'} size={'sm'} />

            <PrimaryInput value={currentPaymentMethod.accountNumber} onChange={(e) => changeValue('accountNumber', e.target.value)} icon={<MdNumbers />} type={'text'} placeholder={'Enter account Number'} label={'Account Number'} name={'accountNumber'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.branchCode} onChange={(e) => changeValue('branchCode', e.target.value)} icon={<BsPass />} type={'text'} placeholder={'Enter Branch Code'} label={'Branch Code'} name={'branchCode'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.iban} onChange={(e) => changeValue('iban', e.target.value)} icon={<CiLock />} type={'text'} placeholder={'Enter IBAN Number'} label={'IBAN'} name={'iban'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.swiftCode} onChange={(e) => changeValue('swiftCode', e.target.value)} icon={<FaCity />} type={'text'} placeholder={'Enter Swift Code'} label={'Swift Code'} name={'swiftCode'} size={'sm'} />

            <PrimaryInput value={currentPaymentMethod.walletId} onChange={(e) => changeValue('walletId', e.target.value)} icon={<BsWallet />} type={'text'} placeholder={'Enter Wallet Id'} label={'Wallet Id'} name={'walletId'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.cryptocurrencyAddress} onChange={(e) => changeValue('cryptocurrencyAddress', e.target.value)} icon={<FaConnectdevelop />} type={'text'} placeholder={'Enter Crypto Address Number'} label={'Cryptocurrency Address'} name={'cryptocurrencyAddress'} size={'sm'} />

            <PrimaryInput value={currentPaymentMethod.paymentEmail} onChange={(e) => changeValue('paymentEmail', e.target.value)} icon={<MdEmail />} type={'text'} placeholder={'Enter Payment Email'} label={'Payment Email'} name={'paymentEmail'} size={'sm'} />
            <PrimaryInput value={currentPaymentMethod.mobileNumber} onChange={(e) => changeValue('mobileNumber', e.target.value)} icon={<BsPhone />} type={'text'} placeholder={'Enter Mobile Number'} label={'Mobile Number'} name={'mobileNumber'} size={'sm'} />

            <PrimaryInput value={currentPaymentMethod.website} onChange={(e) => changeValue('website', e.target.value)} icon={<BsGlobe />} type={'text'} placeholder={'Enter Website'} label={'Website'} name={'website'} size={'sm'} />

            <PrimaryInput value={currentPaymentMethod.details} onChange={(e) => changeValue('details', e.target.value)} type={'text'} placeholder={'Enter Details'} label={'Details'} name={'details'} size={'sm'} />

            <div className='flex justify-end'>
              {CurrentMethod.mode == 'edit' ?

                <div className='flex gap-2'>

                  <PrimaryButton loading={Loading} onClick={updatePaymentMethod} size={'base'} label={'Update'} />
                </div> :
                CurrentMethod.mode == 'new' ?
                  <div className='flex gap-2'>
                    <PrimaryButton loading={Loading} onClick={createPaymentMethod} size={'base'} label={'Add Payment Method'} />
                  </div> : <></>
              }
            </div>
          </div>
        </div>
      </div>}
      {/* <div className='h-screen'> */}
      <GetDataAPIContainer RefreshData={Refresh} callfor={'payment-methods'} SingleId={''} ScreenName={'Payment Method'} data={PaymentMethods} setData={setPaymentMethods}>
        <DeleteDataAPIContainer RefreshData={Refresh} setRefreshData={setRefresh} DeleteData={DeleteData} setDeleteData={setDeleteData} >
          <div className='h-full'>
            {PaymentMethods && PaymentMethods.map((paymentmethod) => (
              <PaymentCard isEditable={true} PaymentMethod={paymentmethod} onEdit={() => { clickedEdit(paymentmethod) }} onDelete={() => { deletePaymentMethod(paymentmethod._id) }} />
            ))}
          </div>
        </DeleteDataAPIContainer>
      </GetDataAPIContainer>
      {/* </div> */}

    </>
  )
}

export default PaymentMethod