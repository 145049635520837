
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import PaymentCard from '../Dashboard Pages/PaymentMethodManagment/PaymentCard';
import { FaLock } from 'react-icons/fa6';
import PrimaryButton from '../../components/Public/Widgets/button.primary';
import Loader from '../../components/Public/Widgets/loader';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { BsFacebook, BsInstagram, BsGithub, BsLink45Deg, BsLinkedin, BsPinterest, BsReddit, BsSnapchat, BsTiktok, BsTwitter, BsWhatsapp, BsYoutube } from 'react-icons/bs';
import { refreshSideBarMobile } from '../../utils/functions';
import { Helmet } from "react-helmet";
import LinkCard from '../Dashboard Pages/LinksManagment/LinkCard';

const MyLink = ({ PublicUser }) => {

    return (

        <div className='no-scrollbar relative bg-white  text-background-500 flex flex-col  max-h-full items-center py-12 gap-2 lg:px-96 px-8 w-full overflow-y-scroll h-screen'>
            <Helmet>
                <title>Go Pay @{PublicUser?.user.username} here - Secure Payment and Contact Details | Go Pay Here</title>
                <meta
                    name="description"
                    content={`Easily pay ${PublicUser?.user.username} through their personalized payment link on Go Pay Here. Access their preferred payment methods, social media links, and contact details. Fast, simple, and secure payment options.`}
                />
            </Helmet>
            <div className='w-24 h-24 rounded-full'>
                <img className='w-24 h-24 object-cover rounded-full' src={PublicUser?.user.image ? PublicUser?.user.image : 'https://res.cloudinary.com/sheikhtabarak/image/upload/v1729937714/default_nbilpg.avif'} alt="" />
            </div>
            <div className='text-xl font-semibold'>{PublicUser?.user.first_name} {PublicUser?.user.last_name} </div>
            <div className='text-xs leading-[5px] pb-2 font-semibold text-slate-500'>@{PublicUser?.user.username}</div>
            <div className='text-center  text-sm leading-[15px]  text-slate-400'>{PublicUser?.user.bio}</div>
            <div className="bg-white w-full h-auto py-2 flex items-center justify-center gap-2 flex-wrap">

                {PublicUser && PublicUser.socialLinks.map((socialLink) => {
                    let PlatformSocialLink = socialLink.url;
                    if (!/^https:\/\//i.test(PlatformSocialLink)) {
                        // Prepend https:// if it doesn't
                        PlatformSocialLink = `https://${socialLink.url.replace(/^http:\/\//i, '')}`;
                    }

                    return <a className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-primary-500 hover:scale-95 duration-300 rounded-lg p-3' target='_blank' href={PlatformSocialLink}>
                        {socialLink.platform == 'Facebook' ?
                            <BsFacebook /> :
                            socialLink.platform == 'Twitter' ?
                                <BsTwitter /> :
                                socialLink.platform == 'Instagram' ?
                                    <BsInstagram /> :
                                    socialLink.platform == 'LinkedIn' ?
                                        <BsLinkedin /> :
                                        socialLink.platform == 'Snapchat' ?
                                            <BsSnapchat /> :
                                            socialLink.platform == 'TikTok' ?
                                                <BsTiktok /> :
                                                socialLink.platform == 'Pinterest' ?
                                                    <BsPinterest /> :
                                                    socialLink.platform == 'Reddit' ?
                                                        <BsReddit /> :
                                                        socialLink.platform == 'YouTube' ?
                                                            <BsYoutube /> :
                                                            socialLink.platform == 'WhatsApp' ?
                                                                <BsWhatsapp /> :
                                                                <BsLink45Deg />}</a>
                })}

            </div>

            {PublicUser && PublicUser.otherLinks && PublicUser.otherLinks.length != 0 ?
                <TabGroup className={'flex flex-col w-full items-center justify-center py-4'}>
                    <TabList className="flex gap-4 justify-center w-full shadow-inner rounded-lg border-[1px] border-slate-200 p-2">
                        <Tab
                            key='payment-methods'
                            className="rounded-lg flex justify-center items-center w-1/2 py-3 text-sm/6 font-semibold focus:outline-none data-[selected]:focus:outline-none data-[selected]:shadow-lg data-[selected]:bg-primary-500 data-[selected]:text-white  data-[selected]:data-[hover]:bg-primary-500 data-[focus]:outline-none">
                            How to Pay
                        </Tab>
                        <Tab
                            key='links'
                            className="rounded-lg flex justify-center items-center w-1/2  py-3 text-sm/6 font-semibold focus:outline-none data-[selected]:focus:outline-none data-[selected]:shadow-lg data-[selected]:bg-primary-500 data-[selected]:text-white data-[selected]:data-[hover]:bg-primary-500 data-[focus]:outline-none">
                            Links
                        </Tab>
                    </TabList>
                    <TabPanels className="mt-8 w-full">
                        <TabPanel key='links' className="rounded-xl bg-white/5">
                            {
                                PublicUser && PublicUser.paymentMethods.map((paymentMethod) => (
                                    <PaymentCard PaymentMethod={paymentMethod} />
                                ))
                            }
                        </TabPanel>
                        <TabPanel key='payment-methods' className="flex flex-col w-full">

                            {
                                PublicUser && PublicUser.otherLinks.map((paymentMethod) => (
                                    <LinkCard Link={paymentMethod} />
                                ))
                            }
                        </TabPanel>
                    </TabPanels>
                </TabGroup> :

                PublicUser && PublicUser.paymentMethods.map((paymentMethod) => (
                    <PaymentCard PaymentMethod={paymentMethod} />
                ))
                // <div>
                //     {
                //         PublicUser && PublicUser.paymentMethods.map((paymentMethod) => (
                //             <PaymentCard PaymentMethod={paymentMethod} />
                //         ))
                //     }
                // </div>
            }

            <div className='fixed h-24 items-end left-auto right-auto bottom-0 w-full flex justify-center pb-4'>
                <div className=''>
                    <Link className='shadow-xl' to={'/'}>
                        <PrimaryButton icon={<FaLock />} className={'!rounded-full shadow-background-500 shadow-md hover:animate-wobble'} size={'base'} label={'Join Go Pay here now'} />
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default MyLink