import React, { useContext } from 'react'
import PrimaryButton from '../Widgets/button.primary'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../utils/authContext'


const PublicHeader = () => {
  const navigate = useNavigate()
  const { Auth } = useContext(AuthContext)


  return (
    <div className='flex justify-between w-full px-4 items-center bg-background-500 border-b-[1px] border-background-400 py-6'>
      <NavLink to={'/'} className='h-16'>
        <img className='h-full' src="/logo.png" alt="" />
      </NavLink>

      {Auth.isLoggedin == true ?
        <div className='flex gap-2'>
          <PrimaryButton className={'!font-semibold tracking-wider'} size={'base'} label={'Dashboard'} loading={false} onClick={() => { navigate('/admin') }} />
        </div>
        :
        <div className='flex gap-2'>
          <PrimaryButton className={'!font-semibold tracking-wider'} size={'base'} label={'Login'} loading={false} onClick={() => { navigate('/login') }} />
          <PrimaryButton className={'!font-semibold tracking-wider bg-white !text-slate-700 hover:bg-slate-200'} size={'base'} label={'Register'} onClick={() => { navigate('/register') }} />
        </div>
      }
    </div>
  )
}

export default PublicHeader