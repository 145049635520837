// authContext.js
import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [Auth, setAuth] = useState({
        isLoggedin: false,
        token: null,
        data: {
            uid: null,
            username: null,
            islocked: false,
        },
        loading: true,
    });

    useEffect(() => {
        const storedAuth = localStorage.getItem('auth');
        if (storedAuth) {
            setAuth({ ...JSON.parse(storedAuth), loading: false });
        } else {
            setAuth({ ...Auth, loading: false });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('auth', JSON.stringify(Auth));
    }, [Auth]);

    const login = (token) => {
        setAuth({
            isLoggedin: true,
            token: token,
            data: null,
            loading: false,
        });
    };

    const logout = () => {
        localStorage.removeItem('auth');
        setAuth({
            isLoggedin: false,
            token: null,
            data: {
                uid: null,
                cid: null,
                urole: null,
            },
            loading: false,
        });

    };

    const refreshAuth = async (token) => {
        try {
            const response = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).get(`/api/auth/validate-token`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if (response.status === 200 || response.status === 204 || response.status === 304) {
                const newData = response.data.decoded;
                setAuth({
                    isLoggedin: true,
                    token: token,
                    data: newData,
                    loading: false,
                });
            } else {
                logout();
            }
        } catch (error) {
            console.error(error);
            logout();
        }
    };

    return (
        <AuthContext.Provider value={{ Auth, setAuth, login, logout, refreshAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };