// PasswordScreen.js
import React, { useEffect, useState } from 'react';
import { BsHandIndex } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/Public/Widgets/button.primary';
// import { MdCancel, MdClose } from 'react-icons/md';
import { TfiClose } from "react-icons/tfi";

const PasswordScreen = ({ wrong, loading, value, setSearchParams, Refresh, setRefresh }) => {
    let paddedDigits = ['', '', '', '']
    if (value) {
        // Convert the value to an array of digits
        let digits = value ? value.toString().split('') : [];

        // Ensure the array has exactly 4 elements, filling any missing spots with empty strings
        paddedDigits = [...digits, '', '', '', ''].slice(0, 4);
        digits = value.toString().split('')
    }


    const [pin, setPin] = useState(paddedDigits);
    const navigate = useNavigate()
    const handleChange = (value, index) => {
        const newPin = [...pin];
        newPin[index] = value;

        // Move to the next input field if the current one is filled
        if (value && index < 3) {
            document.getElementById(`pin-${index + 1}`).focus();
        }

        setPin(newPin);
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && !pin[index]) {
            document.getElementById(`pin-${index - 1}`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearchParams({ passcode: pin.join('') })
        setRefresh(!Refresh)
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-background-500">
            <Link to={'/'} className=' absolute top-10 right-10 text-3xl text-white cursor-pointer'>
                <TfiClose />
            </Link>
            <div className='px-12 lg:px-0'>
                <div className='text-lg text-slate-400 pb-2 text-center'>Profile Lock is currently on</div>
                <div className='text-3xl font-semibold text-white pb-8 text-center'>Enter your PIN to access this profile.</div>
            </div>
            <form onSubmit={handleSubmit} className='flex flex-col items-center'>

                <div id='form-pin' className={`flex space-x-2 pb-6 ${wrong ? 'animate-wobble' : ''}`}>
                    {pin.map((digit, index) => (
                        <input
                            key={index}
                            id={`pin-${index}`}
                            type="text"
                            pattern="[0-9]*"
                            maxLength="1"
                            inputMode='numeric'
                            value={digit}
                            onChange={
                                (e) => {
                                    const newValue = e.target.value.replace(/\D/g, '');
                                    if (newValue.length <= 1) {
                                        handleChange(newValue, index)
                                    }
                                }
                            }
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            className="w-[70px] h-[70px] text-center text-white text-2xl border-2 border-background-100 rounded bg-background-500 focus:outline-none focus:scale-105 duration-300 focus:ring-2 focus:border-none focus:ring-primary-500"
                        />
                    ))}
                </div>

                <PrimaryButton className={'!font-semibold tracking-wider'} size={'base'} label={'Enter Passcode'} loading={loading} type="submit" onClick={handleSubmit} />
            </form>
        </div>
    );
};

export default PasswordScreen;