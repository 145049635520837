import axios from "axios";
import { toast } from "react-toastify";

export async function PostDataAPIFunction({ Message, Recieveddata, callfor, token }) {

    try {
        const CallResponseData = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).post('/api/' + callfor, Recieveddata, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        toast.dismiss()

        if (CallResponseData.data.message) {
            toast.success(CallResponseData.data.message)

        }
        else {
            toast.success(Message ? Message : 'Done')
        }
        return { success: true, data: CallResponseData }

    }
    catch (error) {
        console.log(error)
        if (error.response) {

            if (error.response.data.errors) {
                const ServerErrors = error.response.data.errors
                // toast.dismiss()
                if (ServerErrors) {
                    ServerErrors.map((error) => {
                        toast.error(error.message)
                    })
                }
            }
            else {
                toast.error(error.response.data.message)
            }
        }
        else {
            if (error.code == 'ERR_NETWORK') {
                toast.error('Our server is working right now!')
            }
            // else if (error.code == '"ERR_BAD_RESPONSE"') {
            //     toast.error('Our server is working right now!')

            // }
            // else {
            //     toast.error(error.message)
            // }
        }

        return { success: false, error }
    }
}
