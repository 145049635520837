import { toast } from "react-toastify";

export const copyToClipboard = (name, text) => {

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {

        document.execCommand('copy');
        toast.dismiss()
        toast.success(name + ' Copied');
    } catch (err) {
        toast.error('Failed to copy');
    }
    document.body.removeChild(textArea);

};

export const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
};

export const refreshSideBarMobile = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const Iframe = document.getElementById('publicLinkIframeMobileView');
        if (Iframe) {
            console.log('refreshed');
            Iframe.src = Iframe.src; // This reloads the iframe
        }
    });
    // document.getElementById('publicLinkIframeMobileView').contentWindow.location.reload();
    // document.getElementById('publicLinkIframeMobileView').src = document.getElementById('publicLinkIframeMobileView').src


}



export function throttle(func, delay) {
    let lastCall = 0;
    return (...args) => {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func(...args);
    };
}