import React, { useRef } from 'react'
import PaymentMethod from './PaymentMethodManagment'
import Account from './AccountManagment'
import Socials from './SocialLinksManagment'
import { Navigate, Route, Routes } from 'react-router-dom'
import DashboardLayout from '../../layouts/dashboard.layout'
import Settings from './SettingsManagment'
import { Helmet } from "react-helmet";
import Links from './LinksManagment'


const Dashboard = () => {

    const iframeRef = useRef(null);

    const refreshIframe = () => {
        if (iframeRef.current) {
            console.log('refreshed');
            iframeRef.current.src = iframeRef.current.src; // This reloads the iframe
        }
    }

    return (
        <>
            <DashboardLayout iframeSrc={iframeRef}>
                <Routes>
                    <Route path="/*" element={<Navigate to="/admin/payments" replace />} />
                    <Route path='/payments' element={
                        <>
                            <Helmet>
                                <title>Payment Methods - Admin Dashboard | Go Pay Here</title>
                                <meta name="description" content="Easily manage and update your payment methods in the Go Pay Here admin dashboard. Add bank details, wallets, and more to streamline payments." />
                            </Helmet>
                            <PaymentMethod refreshIframe={refreshIframe} />
                        </>
                    } />
                    <Route path='/links' element={
                        <>
                            <Helmet>
                                <title>All Links - Admin Dashboard | Go Pay Here</title>
                                <meta name="description" content="Manage and customize your custom links from the Go Pay Here admin dashboard, making it easy to show the links with single link" />
                            </Helmet>
                            <Links refreshIframe={refreshIframe} />
                        </>
                    } />
                    <Route path='/social' element={
                        <>
                            <Helmet>
                                <title>Social Links - Admin Dashboard | Go Pay Here</title>
                                <meta name="description" content="Manage and customize your social media links from the Go Pay Here admin dashboard, making it easy for clients to connect with you." />
                            </Helmet>
                            <Socials refreshIframe={refreshIframe} />
                        </>
                    } />
                    <Route path='/account' element={
                        <>
                            <Helmet>
                                <title>Account Managment - Admin Dashboard | Go Pay Here</title>
                                <meta name="description" content="Handle all aspects of your account in one place. Update profile details, password settings, and more in the Go Pay Here admin dashboard." />
                            </Helmet>
                            <Account refreshIframe={refreshIframe} />
                        </>
                    } />
                    <Route path='/settings' element={
                        <>
                            <Helmet>
                                <title>Settings -  Admin Dashboard | Go Pay Here</title>
                                <meta name="description" content="Configure your Go Pay Here account settings effortlessly, from privacy controls to notification preferences, in the admin dashboard." />
                            </Helmet>
                            <Settings refreshIframe={refreshIframe} />
                        </>
                    } />
                </Routes>
            </DashboardLayout>
        </>
    )
}

export default Dashboard