// src/Login.js
import React, { useContext, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/Public/Widgets/button.primary';
import PrimaryInput from '../../components/Public/Widgets/input.primary';
import { AuthContext } from '../../utils/authContext';
import { toast } from 'react-toastify'
import { PostDataAPIFunction } from '../../layouts/api/post.function';
import { throttle } from '../../utils/functions';
const Login = () => {

  const [rotate, setRotate] = useState({});

  const onMouseMove = useCallback(
    (id) =>
      throttle((e) => {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const x = e.clientX - box.left;
        const y = e.clientY - box.top;
        const centerX = box.width / 2;
        const centerY = box.height / 2;
        const rotateX = (y - centerY) / 7;
        const rotateY = (centerX - x) / 7;


        setRotate((prev) => ({
          ...prev,
          [id]: { x: rotateX, y: rotateY },
        }));
      }, 100),
    []
  );

  const onMouseLeave = (id) => {
    setRotate((prev) => ({
      ...prev,
      [id]: { x: 0, y: 0 },
    }));
  };

  const [UserLogin, setUserLogin] = useState({
    emailOrUsername: '',
    password: ''
  })

  const { login, refreshAuth } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const APIResponse = await PostDataAPIFunction({
        Recieveddata: {
          emailOrUsername: UserLogin.emailOrUsername,
          password: UserLogin.password,
        },
        callfor: 'auth/login',
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setTimeout(() => {
          setLoading(false);
          console.log(APIResponse.data)
          if (APIResponse.data.data.username) {
            toast.dismiss()
            toast.success(APIResponse.data.data.username)
          }
          navigate('/admin/')
        }, 2000);

        const UserData = APIResponse.data.data
        console.log(UserData)

        const datatoken = UserData.token;
        console.log(UserData.token)
        const tokenString = JSON.stringify(datatoken);
        let token = datatoken;


        if (token) { token = tokenString.replace(/"/g, ''); }
        console.log(token)
        login(token)
        refreshAuth(token)
      }

      else {
        setLoading(false);
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };


  const valueChange = (label, value) => {
    setUserLogin({
      ...UserLogin,
      [label]: value
    })
  }

  return (
    <div className=" flex flex-col-reverse lg:flex-row items-center justify-center h-screen">

      <div className="flex flex-col justify-center h-screen no-scrollbar overflow-scroll lg:w-1/2 px-12 lg:px-28 bg-white  rounded-lg">

        <Link to={'/'} >
          <img className='h-16 mb-8' src="/logo-light.png" alt="" />
        </Link>

        <h2 className="text-4xl font-bold">Welcome back!</h2>
        <h3 className='mb-6 text-slate-500 py-4'>Login to go pay here</h3>
        <form onSubmit={handleSubmit}>

          <PrimaryInput
            label={'Email/username'} type={'text'} name={'emailOrUsername'} placeholder={'Email or Username'}
            onChange={(e) => valueChange('emailOrUsername', e.target.value)} value={UserLogin.emailOrUsername}
            icon={
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=" stroke-current ml-1">
                <path d="M1.87651 3.83325L6.79885 7.07932C8.55702 8.13423 9.43612 8.66169 10.402 8.6387C11.3678 8.61572 12.2208 8.04705 13.9268 6.90971L18.1232 3.83325M8.33317 14.6666H11.6665C14.8092 14.6666 16.3805 14.6666 17.3569 13.6903C18.3332 12.714 18.3332 11.1426 18.3332 7.99992C18.3332 4.85722 18.3332 3.28587 17.3569 2.30956C16.3805 1.33325 14.8092 1.33325 11.6665 1.33325H8.33317C5.19047 1.33325 3.61913 1.33325 2.64281 2.30956C1.6665 3.28587 1.6665 4.85722 1.6665 7.99992C1.6665 11.1426 1.6665 12.714 2.64281 13.6903C3.61913 14.6666 5.19047 14.6666 8.33317 14.6666Z" stroke="" stroke-width="1.5" stroke-linecap="round"></path>
              </svg>
            } />

          <PrimaryInput
            label={'Password'} type={'password'} name={'password'} placeholder={'Password'}
            onChange={(e) => valueChange('password', e.target.value)} value={UserLogin.password}
            icon={
              <svg width="26" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_425_4503)">
                  <path d="M13.9 14.7C13.3 15.3 12.6 15.6 11.8 15.6C11 15.6 10.2 15.3 9.70005 14.7C9.10005 14.1 8.80005 13.4 8.80005 12.6C8.80005 11.8 9.10005 11 9.70005 10.5L11.8 12.6L13.9 14.7Z" className="my-path"></path>
                  <path d="M4 4.7998L19.6 20.3998" stroke-linecap="round" className="my-path"></path>
                  <path d="M17.7 18.4998C16 19.5998 14 20.1998 11.8 20.1998C7.70003 20.1998 4.10002 17.8998 2.30002 14.4998C1.60002 13.3998 1.60002 11.9998 2.30002 10.8998C3.20002 9.19981 4.50002 7.7998 6.10002 6.7998" className="my-path"></path>
                  <path d="M8.40002 5.7001C9.50002 5.3001 10.6 5.1001 11.8 5.1001C15.9 5.1001 19.5 7.4001 21.3 10.8001C21.9 11.9001 21.9 13.3001 21.3 14.4001C20.8 15.3001 20.2 16.1001 19.5 16.8001" stroke-linecap="round" className="my-path"></path>
                </g>
                <defs>
                  <clipPath id="clip0_425_4503">
                    <rect width="21.6" height="17.2" fill="white" transform="translate(1 4)"></rect>
                  </clipPath>
                </defs>
              </svg>
            } />

          <PrimaryButton size={'base'} loading={loading} label="Login" type="submit" />
          <PrimaryButton onClick={(e) => {
            e.preventDefault()
            navigate('/register')
          }} size={'base'} className={'bg-white border-2 mt-3 border-primary-500 !text-primary-500 hover:!text-white'} label="Register" />

          <div className='pt-6'>
            <div>Did't remember your password ? <Link to={'/forget-password'} className='text-primary-500'>Reset it now</Link></div>
          </div>


        </form>
      </div>

      <div

        className='lg:w-1/2 bg-background-500 rounded-l-xl absolute lg:relative flex items-center -z-10 lg:z-0 h-full'

      >
        <img
          className='cursor-pointer'
          style={{
            transform: `rotateX(${rotate['user-friendly-dashboard-img']?.x || 0}deg) rotateY(${rotate['user-friendly-dashboard-img']?.y || 0}deg)`,
            transition: "all 2000ms cubic-bezier(0.18, 0.38, 0.18, 0.39) 0s",
            transitionDuration: '1000ms'
          }}
          onMouseMove={onMouseMove('user-friendly-dashboard-img')}
          onMouseLeave={() => onMouseLeave('user-friendly-dashboard-img')}
          src='/user-friendly-dashboard.png' alt="" />
      </div>

    </div>
  );
};

export default Login;