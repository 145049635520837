import React from 'react'
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll';


const PublicFooter = () => {
    return (
        <footer className='bg-slate-100 rounded-t-xl text-sm  py-5 px-12 text-slate-950'>
            <div className='lg:flex text-center justify-between items-end border-b-[1px] border-slate-500 pb-2 mb-2'>
                <div className=''>
                    <div className='flex  lg:justify-start justify-center'>
                        <Link className='cursor-pointer' to={'hero'} smooth duration={1000} >
                            <img
                                className="relative h-16"
                                src="/logo-light.png"
                                alt="Go Pay here logo"
                            />
                        </Link>   </div>
                    <div className='py-2'>Share all your payment methods in one secure, easy-to-access link.</div>
                </div>


                <div className='space-y-4 lg:space-y-2'>
                    <div className='flex gap-4 text-3xl justify-center lg:justify-end pt-2'>
                        <NavLink className='cursor-pointer' to={'https://github.com/sheikh-tabarak/go-payhere'}><FaGithub /></NavLink>
                        <NavLink className='cursor-pointer' to={'https://linkedin.com/in/sheikhtabarak'}><FaLinkedin /></NavLink>
                        <NavLink className='cursor-pointer' to={'https://instagram.com/smtdigitaltech'}><FaInstagram /></NavLink>
                    </div>
                    <div className='flex gap-2 justify-center items-center'>
                    </div>
                </div>
            </div>

            <div className='flex justify-between w-full '>
                <div>© 2024 <Link className='px-1 text-primary-500 font-semibold' smooth duration={1000} to={'hero'}> Go Pay Here</Link> |  All rights reserved.</div>
                <div>
                    <a href='https://mail.google.com/mail/u/0/?fs=1&tf=cm&to=admin@sheikhtabarak.me&su=GoPayhere+|+Suggestions,+Feedback+or+Improvements' target='_blank' className='px-1 text-background-500 hover:text-primary-500 text-xm duration-300 cursor-pointer' smooth duration={1000} to={'hero'}> Suggestions & Improvements </a> 
                    <a href='https://sheikhtabarak.me' target='_blank' className='px-1 text-background-500 hover:text-primary-500 text-xm duration-300 cursor-pointer' smooth duration={1000} to={'hero'}> About </a> 
                    <a href='https://sheikhtabarak.me' target='_blank' className='px-1 text-background-500 hover:text-primary-500 text-xm duration-300 cursor-pointer' smooth duration={1000} to={'hero'}> Contact </a> 
                    </div>

            </div>

        </footer>
    )
}

export default PublicFooter