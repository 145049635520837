import React from 'react'
import PublicHeader from '../components/Public/Header/public.header'
import PublicFooter from '../components/Public/Footer/public.footer'

const PublicLayout = ({ children }) => {
  return (
    <>
      <PublicHeader />
      {children}
      <PublicFooter/>
    </>
  )
}

export default PublicLayout