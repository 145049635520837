import React, { useContext, useEffect, useState } from 'react'
import PrimarySelectBox from '../../../components/Public/Widgets/select.widget'
import PrimaryInput from '../../../components/Public/Widgets/input.primary'
import { FaIcons, FaShare } from 'react-icons/fa6'
import { BsLink45Deg } from 'react-icons/bs'
import { MdDescription, MdGroupAdd, MdOutlineAlternateEmail } from 'react-icons/md'
import LinkCard from './LinkCard'
import PrimaryButton from '../../../components/Public/Widgets/button.primary'
import GetDataAPIContainer from '../../../layouts/api/get.container'
import { scrollToSection } from '../../../utils/functions'
import { PostDataAPIFunction } from '../../../layouts/api/post.function'
import { AuthContext } from '../../../utils/authContext'
import { PutDataAPIFunction } from '../../../layouts/api/put.function'
import DeleteDataAPIContainer from '../../../layouts/api/delete.container'

const Links = ({ refreshIframe }) => {

  const { Auth } = useContext(AuthContext)
  const [isNewMode, setisNewMode] = useState(true)
  const [DefinedPlatforms, setDefinedPlatforms] = useState('')
  const [isCustomPlatform, setisCustomPlatform] = useState(true)
  const [Refresh, setRefresh] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [Links, setLinks] = useState([])

  const [currentLinkDetails, setcurrentLinkDetails] = useState({
    url: '',
    icon: '',
    decs: '',
    group: '',
    title: '',
  })

  useEffect(() => {
    refreshIframe()
  }, [Refresh])

  const [DeleteData, setDeleteData] = useState({
    data: '',
    callfor: 'links',
    status: false,
    title: 'Are you sure you want to delete this Link?',
    message: 'Your are deleting a link from database and we are informing you that this action cannot be undone.',
    successMessage: 'Link has been Deleted Successfully'
  })


  const deleteData = (data) => {
    setDeleteData({
      data: data,
      callfor: 'links',
      status: true,
      title: 'Do you want to delete this Link?',
      message: 'Your are deleting a link from database and we are informing you that this action cannot be undone.',
      successMessage: 'Link has been Deleted Successfully'
    })

  }

  const createLink = async () => {
    setLoading(true);
    try {
      const APIResponse = await PostDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          url: currentLinkDetails.url,
          icon: currentLinkDetails.icon,
          decs: currentLinkDetails.decs,
          group: currentLinkDetails.group,
          title: currentLinkDetails.title,
        },
        callfor: 'links',
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setisNewMode(true)
        setcurrentLinkDetails({
          url: '',
          icon: '',
          decs: '',
          group: '',
          title: '',
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateLink = async () => {
    setLoading(true);
    try {
      const APIResponse = await PutDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          url: currentLinkDetails.url,
          icon: currentLinkDetails.icon,
          decs: currentLinkDetails.decs,
          group: currentLinkDetails.group,
          title: currentLinkDetails.title,
        },
        callfor: 'links/' + currentLinkDetails._id,
      })

      if (APIResponse.success && APIResponse.data.status === 200) {
        setLoading(false);
        setRefresh(!Refresh)
        setisNewMode(true)
        setcurrentLinkDetails({
          url: '',
          icon: '',
          decs: '',
          group: '',
          title: '',
        })
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {

  //   if (
  //     DefinedPlatforms === 'Facebook' ||
  //     DefinedPlatforms === 'Instagram' ||
  //     DefinedPlatforms === 'Twitter' ||
  //     DefinedPlatforms === 'TikTok' ||
  //     DefinedPlatforms === 'YouTube' ||
  //     DefinedPlatforms === 'Pinterest' ||
  //     DefinedPlatforms === 'LinkedIn' ||
  //     DefinedPlatforms === 'Reddit' ||
  //     DefinedPlatforms === 'Snapchat' ||
  //     DefinedPlatforms === 'WhatsApp'
  //   ) {
  //     setcurrentLinkDetails({
  //       ...currentLinkDetails,
  //       platform: DefinedPlatforms,
  //     })
  //     setisCustomPlatform(false)
  //   }
  //   else {
  //     setisCustomPlatform(true)
  //   }
  // }, [DefinedPlatforms])


  const changeValue = (key, value) => {
    setcurrentLinkDetails({
      ...currentLinkDetails,
      [key]: value
    })
  }

  // useEffect(() => {
  //   if (
  //     currentLinkDetails.platform !== 'Facebook' ||
  //     currentLinkDetails.platform !== 'Instagram' ||
  //     currentLinkDetails.platform !== 'Twitter' ||
  //     currentLinkDetails.platform !== 'TikTok' ||
  //     currentLinkDetails.platform !== 'YouTube' ||
  //     currentLinkDetails.platform !== 'Pinterest' ||
  //     currentLinkDetails.platform !== 'LinkedIn' ||
  //     currentLinkDetails.platform !== 'Reddit' ||
  //     currentLinkDetails.platform !== 'Snapchat' ||
  //     currentLinkDetails.platform !== 'WhatsApp'
  //   ) {

  //     console.log('I am called' + currentLinkDetails.platform)
  //     console.log(currentLinkDetails.platform !== 'WhatsApp')
  //     console.log(currentLinkDetails.platform !== 'Facebook')
  //     // setDefinedPlatforms('')
  //   }

  //   else {
  //     setisCustomPlatform(false)
  //     setDefinedPlatforms(currentLinkDetails)

  //   }
  // }, [currentLinkDetails.platform])

  // const Platforms = [
  //   { name: 'Others', id: '' },
  //   { name: 'Facebook', id: 'Facebook' },
  //   { name: 'Twitter', id: 'Twitter' },
  //   { name: 'Instagram', id: 'Instagram' },
  //   { name: 'LinkedIn', id: 'LinkedIn' },
  //   { name: 'Snapchat', id: 'Snapchat' },
  //   { name: 'TikTok', id: 'TikTok' },
  //   { name: 'Pinterest', id: 'Pinterest' },
  //   { name: 'Reddit', id: 'Reddit' },
  //   { name: 'YouTube', id: 'YouTube' },
  //   { name: 'WhatsApp', id: 'WhatsApp' }
  // ];


  return (

    <div className='pt-4'>

      <div className='flex justify-start items-center gap-4 pb-4'>
        <div className='text-primary-500 font-semibold text-lg'>Custom Links
        </div>
        <span class="flex bg-red-500 text-white text-[9px] font-bold px-2 py-1 rounded-lg">
          Newly added Feature
        </span>
      </div>

      <PrimaryInput value={currentLinkDetails.title} onChange={(e) => changeValue('title', e.target.value)} type={'text'} placeholder={'Enter Title of Link'} label={'Title'} name={'title'} size={'sm'} />
      <PrimaryInput value={currentLinkDetails.icon} onChange={(e) => changeValue('icon', e.target.value)} icon={<FaIcons />} type={'text'} placeholder={'Enter the link to the icon to show'} label={'Icon'} name={'icon'} size={'sm'} />
      <PrimaryInput value={currentLinkDetails.group} onChange={(e) => changeValue('group', e.target.value)} icon={<MdGroupAdd />} type={'text'} placeholder={'Enter Group of Link'} label={'Group'} name={'group'} size={'sm'} />
      <PrimaryInput value={currentLinkDetails.decs} onChange={(e) => changeValue('decs', e.target.value)} icon={<MdDescription />} type={'text'} placeholder={'Enter Description of Link'} label={'Description'} name={'decs'} size={'sm'} />
      <PrimaryInput value={currentLinkDetails.url} onChange={(e) => changeValue('url', e.target.value)} icon={<BsLink45Deg />} type={'text'} placeholder={'URL'} label={'Url'} name={'url'} size={'sm'} />

      <div className='pb-4 flex justify-end'>
        <div>
          {isNewMode ?
            <PrimaryButton onClick={createLink} loading={Loading} size={'base'} label={'Create a new Link'} /> :
            <div className='flex gap-4'>
              <PrimaryButton
                onClick={() => {
                  setisNewMode(true)
                  setcurrentLinkDetails({
                    url: '',
                    icon: '',
                    decs: '',
                    group: '',
                    title: '',
                  })
                }} size={'base'} className={'bg-red-600 hover:bg-red-500'} label={'Cancel'} />
              <PrimaryButton onClick={updateLink} loading={Loading} size={'base'} className={'bg-blue-700 hover:bg-blue-600'} label={'Update'} />
            </div>
          }
        </div>
      </div>
      <div className='h-full'>
        <GetDataAPIContainer RefreshData={Refresh} callfor={'links'} SingleId={''} ScreenName={'Links'} data={Links} setData={setLinks}>
          <DeleteDataAPIContainer RefreshData={Refresh} setRefreshData={setRefresh} DeleteData={DeleteData} setDeleteData={setDeleteData} >
            {Links && Links.map((link) => (<LinkCard
              onDelete={() => deleteData(link._id)}
              isEditable={true}
              onEdit={() => {
                setcurrentLinkDetails(link)
                scrollToSection('top')
                setisNewMode(false)
              }}
              Link={link} />))}
          </DeleteDataAPIContainer>
        </GetDataAPIContainer>

      </div>
    </div>
  )
}

export default Links