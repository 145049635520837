import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { BsLink45Deg, BsTools } from "react-icons/bs";
import { FaToolbox, FaUsers } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa";
import { MdOutlineDashboard, MdPayment } from 'react-icons/md';
import { CiLogout, CiSettings, CiUser } from 'react-icons/ci';
import { AiOutlineLoading, AiOutlineProfile } from 'react-icons/ai';
import { AuthContext } from '../../../utils/authContext';
import SidebarLinkGroup from './SidebarLinkGroups';
import { toast } from 'react-toastify';
import GetDataAPIContainer from '../../../layouts/api/get.container';
import { IoShareSocial } from 'react-icons/io5';


const LeftSidebar = ({ sidebarOpen, setSidebarOpen }) => {

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState()
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate()
    const { Auth, logout } = useContext(AuthContext)
    const trigger = useRef(null);
    const sidebar = useRef(null);

    const handleLogout = () => {
        setLoading(true);
        toast('We are logging you out')
        setTimeout(() => {
            logout()
            localStorage.clear();
            navigate('/login', { replace: true });
            setLoading(false);
            closeAccountBox()
        }, 2000);
    }

    useEffect(() => {
        const dashboard = document.getElementById('dashboard')
        dashboard.addEventListener('click', (event) => {
            handleClickOutside(event);
        });
    })


    function handleClickOutside(event) {
        const div = document.getElementById('account-name'); // Replace with your div's ID
        if (div && !div.contains(event.target)) {
            closeAccountBox();
        }
    }

    const toogleAccountBox = () => {
        const AccountBox = document.getElementById('account-box');
        if (AccountBox.classList.contains('-bottom-12')) {
            AccountBox.classList.remove('-bottom-12')
            AccountBox.classList.add('bottom-26')
        }
        else {
            AccountBox.classList.remove('bottom-26')
            AccountBox.classList.add('-bottom-12')
        }
    }

    const closeAccountBox = () => {
        document.getElementById('account-box').classList.add('-bottom-12')
        document.getElementById('account-box').classList.remove('bottom-26')
    }


    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );

    useEffect(() => {
        const clickHandler = (event) => {
            if (!sidebar.current || !trigger.current) return;
            if (!sidebarOpen || sidebar.current.contains(event.target) || trigger.current.contains(event.target))
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    }, []);


    useEffect(() => {
        const keyHandler = (event) => {
            if (!sidebarOpen || event.keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });



    return (
        <aside
            ref={sidebar}
            className={`fixed bottom-0 left-0 right-0 lg:top-0 z-9999 flex h-[55px] lg:h-screen w-full lg:w-72.5 flex-col overflow-y-hidden bg-background-500 duration-300 ease-linear lg:translate-x-0 `}

        >
            {/* <!-- SIDEBAR HEADER --> */}

            <div className="hidden lg:flex items-center justify-between mt-2 gap-2 px-6 lg:pl-10 py-5.5 lg:pt-4 lg:pb-0 text-white font-bold hover:text-primary font-poppins text-4xl">

                <NavLink to="/admin/">
                    <img src='/logo-x.png' alt="Logo" />
                </NavLink>

                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <svg
                        className="fill-current"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                            fill=""
                        />
                    </svg>
                </button>
            </div>
            {/* <!-- SIDEBAR HEADER --> */}

            <div className="no-scrollbar flex flex-col  overflow-y-auto duration-300 ease-linear">
                {/* <!-- Sidebar Menu --> */}
                <nav className="no-scrollbar overflow-hidden lg:pt-4 px-0 lg:mt-9 lg:px-6 text-sm flex flex-row lg:flex-col justify-between h-screen">
                    <div>


                        <ul className="mb-6 flex flex-row lg:flex-col lg:gap-1.5">
                            {/* <!-- Menu Item Payment --> */}
                            <li>
                                <NavLink
                                    to="/admin/payments"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                    className={`group relative flex items-center lg:gap-2.5 lg:rounded px-6 lg:px-4 py-4 font-medium hover:text-white text-white duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary ${pathname.includes('payments') &&
                                        'bg-primary dark:bg-primary text-white'
                                        }`}>
                                    <MdPayment className='text-2xl lg:text-lg' />
                                    <span className='hidden lg:flex'> Payment Methods</span>
                                </NavLink>
                            </li>
                            {/* <!-- Menu Item Payment --> */}

                            {/* <!-- Menu Item   Links --> */}
                            <li>
                                <NavLink
                                    to="/admin/links"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                    className={`group relative flex items-center lg:gap-2.5 lg:rounded px-6 lg:px-4  py-4 font-medium hover:text-white text-white  duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary ${pathname.includes('links') &&
                                        'bg-primary dark:bg-primary text-white'
                                        }`}

                                >
                                    <BsLink45Deg className='text-2xl lg:text-lg' />
                                    <span className='hidden lg:flex'> Links</span>
                                </NavLink>
                            </li>
                            {/* <!-- Menu Item   Social Profiles --> */}

                            {/* <!-- Menu Item   Social Profiles --> */}
                            <li>
                                <NavLink
                                    to="/admin/social"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                    className={`group relative flex items-center lg:gap-2.5 lg:rounded px-6 lg:px-4  py-4 font-medium hover:text-white text-white  duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary ${pathname.includes('social') &&
                                        'bg-primary dark:bg-primary text-white'
                                        }`}

                                >
                                    <IoShareSocial className='text-2xl lg:text-lg' />
                                    <span className='hidden lg:flex'> Social Profiles</span>
                                </NavLink>
                            </li>
                            {/* <!-- Menu Item   Social Profiles --> */}

                            {/* <!-- Menu Item Payment --> */}
                            <li>
                                <NavLink
                                    to="/admin/settings"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                    className={`group relative flex items-center lg:gap-2.5 lg:rounded px-6 lg:px-4  py-4 font-medium hover:text-white text-white  duration-300 ease-in-out hover:bg-primary dark:hover:bg-primary ${pathname.includes('settings') &&
                                        'bg-primary dark:bg-primary text-white'
                                        }`}

                                >
                                    <CiSettings className='text-2xl lg:text-lg' />
                                    <span className='hidden lg:flex'> Settings</span>
                                </NavLink>
                            </li>
                            {/* <!-- Menu Item Payment --> */}
                        </ul>
                    </div>

                    <div className='relative flex flex-col justify-end h-[150px] lg:h-32'>
                        <div id='account-box' className={`flex lg:absolute -bottom-12 duration-700 lg:h-24 text-white w-full`}>
                            <div className={`lg:h-full group relative flex items-center lg:w-full lg:gap-2.5 lg:rounded px-2 lg:px-4  font-medium hover:text-white text-white duration-500 ease-in-out lg:border-2 lg:border-background-400 cursor-pointer`}>
                                <ul className='flex flex-row lg:flex-col w-full border-l-[1px] border-background-300 lg:border-none'>
                                    <li onClick={() => {
                                        closeAccountBox()
                                        navigate('/admin/account')
                                    }}
                                        className={` py-4 lg:px-4 lg:py-2 flex items-center gap-3  hover:bg-primary lg:rounded px-6 w-full duration-500 ${pathname.includes('account') && 'bg-primary'}`}>
                                        <CiUser className='text-white text-2xl lg:text-lg ' />
                                        <span className='hidden lg:flex'> Account</span>
                                    </li>
                                    <li onClick={() => {
                                        handleLogout()
                                    }} className=' py-4 lg:px-4 lg:py-2 flex items-center gap-3 hover:bg-red-500 lg:rounded px-6 w-full duration-500'>
                                        {loading ? <AiOutlineLoading className='animate-spin' /> : <CiLogout className='text-white text-2xl lg:text-lg' />}
                                        <span className='hidden lg:flex'>Logout</span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='z-10 bg-background-500 min-h-24 '>
                            <div id='account-button' onClick={toogleAccountBox} className={`min-h-16 hidden lg:flex group relative items-center gap-2.5 rounded px-4 py-4 font-medium hover:text-white text-white duration-500 ease-in-out border-2 border-background-400 hover:border-primary-500 cursor-pointer ${pathname.includes('account') &&
                                'bg-primary dark:bg-primary text-white'
                                }`}>

                                <GetDataAPIContainer SingleId={''} ScreenName={'Profile'} noDataContainer={<div>No User Found</div>} data={user} setData={setUser} callfor={'users'}>
                                    <div className='flex gap-4'>
                                        <img className='w-10 h-10 rounded-full object-cover' src={user ? user.image : 'https://res.cloudinary.com/sheikhtabarak/image/upload/v1729937714/default_nbilpg.avif'} alt="" />
                                        <div>
                                            <span className='hidden lg:flex flex-col'>
                                                <h2>{user && user.first_name} </h2>
                                                <p className={`text-sm  ${pathname.includes('account') ? 'text-slate-200' : 'text-slate-500'}`}>@{user && user.username}</p>
                                            </span>
                                        </div>
                                    </div>
                                </GetDataAPIContainer>

                            </div>

                        </div>

                    </div>
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    )
}

export default LeftSidebar