import React from 'react'
import { BsShare, BsShareFill } from 'react-icons/bs'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const MobileHeader = () => {
    return (
        <div className=' w-full py-12 lg:hidden'>
            <div className='fixed z-999 right-0 left-0 w-full top-0 px-8 py-4 items-center bg-background-500 text-white flex justify-between'>
                <Link to={'/admin'} className="logo">
                    <img className='h-12' src="/logo-x.png" alt="" />
                </Link>
                <Link to={'/admin/settings'}>
                    <BsShareFill />
                </Link>
            </div>
        </div>
    )
}
export default MobileHeader