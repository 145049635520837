import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../utils/authContext';


const RightSidebar = ({ iframeSrc, sidebarOpen, setSidebarOpen }) => {

    const { Auth } = useContext(AuthContext)
    const navigate = useNavigate()



    return (
        <aside className={`hidden absolute right-0 top-0 z-9999 lg:flex py-4 h-screen w-72.5 lg:flex-col justify-between overflow-y-hiddenduration-300 ease-linear   lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[500px] w-[258px]">
                <div className="w-[148px] h-[18px] bg-black top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute" ></div>
                <div className="h-[46px] w-[3px] bg-black absolute -start-[17px] top-[124px] rounded-s-lg" />
                <div className="h-[46px] w-[3px] bg-black absolute -start-[17px] top-[178px] rounded-s-lg" />
                <div className="h-[64px] w-[3px] bg-black absolute -end-[17px] top-[142px] rounded-e-lg" />
                <div className="rounded-[2rem] overflow-hidden w-[230px] h-[472px] bg-white dark:bg-gray-800">
                    <div style={{ height: '100vh', width: '100%' }}>
                        {/* <img
                            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-light.png"
                            className="dark:hidden w-[230px] h-[472px]"
                            alt=""
                        />
                        <img
                            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-2-dark.png"
                            className="hidden dark:block w-[230px] h-[472px]"
                            alt=""
                        /> */}

                        <iframe
                            ref={iframeSrc}
                            id="publicLinkIframeMobileView"
                            src={`${process.env.REACT_APP_CLIENT_URL}/${Auth.data.username}?passcode=${Auth.data.profile_password}`}
                            title="Mobile Viewport Iframe"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                overflow: 'hidden',
                                zoom: '0.65' // Adjust zoom level as needed (1.0 is 100%)
                                // transform: 'scale()', // Adjust scale as needed
                            }} />


                        {/* <iframe src="http://localhost:3000/ssdasd" frameborder="0"></iframe> */}
                    </div>
                </div>
                {/* <!-- other elements and content --> */}
            </div>


        </aside>
    );
};

export default RightSidebar;