import React, { useState } from 'react';
import { BsCircle, BsCircleFill, BsCopy, BsFacebook, BsInstagram, BsLink45Deg, BsLinkedin, BsPinterest, BsReddit, BsSnapchat, BsTiktok, BsTwitter, BsWhatsapp, BsYoutube } from 'react-icons/bs';
import { toast } from "react-toastify";
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';

const SocialCard = ({ socialLink, onEdit, onDelete, isEditable }) => {


    return (
        <div className="border-[1px] border-background-100 rounded-lg mb-4 w-full h-content relative">
            <div className={`flex gap-4 items-center p-4 bg-gray-100 cursor-pointer hover:bg-gray-200 transition shadow-xl rounded-lg `}>
                {socialLink.url &&
                    <div className='rounded-md w-12 h-12'>

                        <div onClick={onEdit} className='bg-white text-lg hover:bg-slate-50 border-[1px] flex border-slate-200 text-primary-500 hover:scale-95 duration-300 rounded-lg p-3' >

                            {socialLink.platform == 'Facebook' ?
                                <BsFacebook /> :
                                socialLink.platform == 'Twitter' ?
                                    <BsTwitter /> :
                                    socialLink.platform == 'Instagram' ?
                                        <BsInstagram /> :
                                        socialLink.platform == 'LinkedIn' ?
                                            <BsLinkedin /> :
                                            socialLink.platform == 'Snapchat' ?
                                                <BsSnapchat /> :
                                                socialLink.platform == 'TikTok' ?
                                                    <BsTiktok /> :
                                                    socialLink.platform == 'Pinterest' ?
                                                        <BsPinterest /> :
                                                        socialLink.platform == 'Reddit' ?
                                                            <BsReddit /> :
                                                            socialLink.platform == 'YouTube' ?
                                                                <BsYoutube /> :
                                                                socialLink.platform == 'WhatsApp' ?
                                                                    <BsWhatsapp /> :
                                                                    <BsLink45Deg />
                            }

                        </div>

                    </div>}
                <div className='flex justify-between w-full items-center'>
                    <div className='w-full' >
                        <div>
                            {socialLink && socialLink.platform ? socialLink.platform : 'Social Media Platform'}
                        </div>
                        <div className='text-xs text-slate-400'>
                            {socialLink && socialLink.url ? socialLink.url : 'https://facebook.com/abc'}
                        </div>
                    </div>
                    {isEditable && isEditable == true && <div>
                        <span className='flex gap-4 text-lg items-center absolute right-0 top-4 bg-white px-2'>
                            <div onClick={onEdit} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-primary-500 hover:scale-95 duration-300 rounded-lg p-3' >
                                <CiEdit />
                            </div>

                            <div onClick={onDelete} className='bg-white hover:bg-slate-50 border-[1px] flex border-slate-200 text-red-500 hover:scale-95 duration-300 rounded-lg p-3'>
                                <MdDelete />
                            </div>
                        </span>
                    </div>}
                </div>
            </div >
        </div >
    )
}

export default SocialCard