import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ handleLogin }) => {


    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID}>
            <GoogleLogin
                onSuccess={handleLogin}
                onFailure={handleLogin}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;