import axios from 'axios';
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
// import ConfirmationBox from '../../components/Widgets/Global/ConfirmationBox';
import { AuthContext } from '../../utils/authContext';
import PopupBox from '../../components/Public/Widgets/popup.primary';

const DeleteDataAPIContainer = ({ children, DeleteData, setDeleteData, isthirdButton, RefreshData, setRefreshData }) => {

    const { Auth } = useContext(AuthContext)
    const [Loading, setLoading] = useState(false);

    function open() {
        setDeleteData({
            ...DeleteData,
            status: true
        })
    }

    function close() {
        setDeleteData({
            ...DeleteData,
            status: false
        })
    }

    const DeleteAPIFunction = async () => {

        setLoading(true)
        try {
            const response = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).delete('/api/' + DeleteData.callfor + '/' + DeleteData.data, {
                headers: {
                    Authorization: `Bearer ${Auth?.token}`
                }
            });
            if (response.data.message) {
                toast.success(response.data.message)
            }
            else {
                toast.success(DeleteData.successMessage ? DeleteData.successMessage : 'Deleted Successfully')
            }
            setRefreshData(!RefreshData)
            setLoading(false)
            close()
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.dismiss()
                    toast.error('Bad Request: The request was invalid or cannot be processed');
                } else if (error.response.status === 401) {
                    toast.dismiss()
                    // setError('Authorized')
                    toast.error('Unauthorized: You are not authorized to access this resource');
                } else if (error.response.status === 403) {
                    toast.dismiss()
                    // setError('Permission')
                    toast.error('Forbidden: You do not have permission to access this resource');
                } else if (error.response.status === 404) {
                    toast.dismiss()
                    toast.error('Not Found: The requested resource could not be found');
                } else if (error.response.status === 405) {
                    toast.dismiss()
                    // setError('Request')
                    toast.error('Method Not Allowed: The requested method is not supported');
                } else if (error.response.status === 408) {
                    toast.dismiss()
                    toast.error('Request Timeout: The request timed out');
                } else if (error.response.status === 422) {
                    toast.dismiss()
                    toast.error('Unprocessable Entity: The request was well-formed but cannot be processed');
                } else if (error.response.status === 500) {
                    toast.dismiss()
                    toast.error('Internal Server Error: An unexpected error occurred');
                } else {
                    toast.dismiss()
                    toast.error('Unknown Error: An unexpected error occurred');
                }
            } else if (error.request) {
                toast.dismiss()
                toast.error('Network Error: Unable to connect to the server');

            } else {
                toast.dismiss()
                toast.error('An unexpected error occurred');
            }
            setLoading(false)
            close()
        }
    };

    const UpdateAPIFunction = async () => {
        setLoading(true)
        try {
            const response = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).put('/api/' + DeleteData.callfor + '/' + DeleteData.data + '/deactivate', {},
                {
                    headers: {
                        Authorization: `Bearer ${Auth?.token}`
                    }
                }
            );
            toast.success('Equipment is Deactivated')
            setLoading(false)
            close()
            return response.data;

        } catch (error) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.dismiss()
                    toast.error('Bad Request: The request was invalid or cannot be processed');
                } else if (error.response.status === 401) {
                    toast.dismiss()
                    // setError('Authorized')
                    toast.error('Unauthorized: You are not authorized to access this resource');
                } else if (error.response.status === 403) {
                    toast.dismiss()
                    // setError('Permission')
                    toast.error('Forbidden: You do not have permission to access this resource');
                } else if (error.response.status === 404) {
                    toast.dismiss()
                    toast.error('Not Found: The requested resource could not be found');
                } else if (error.response.status === 405) {
                    toast.dismiss()
                    // setError('Request')
                    toast.error('Method Not Allowed: The requested method is not supported');
                } else if (error.response.status === 408) {
                    toast.dismiss()
                    toast.error('Request Timeout: The request timed out');
                } else if (error.response.status === 422) {
                    toast.dismiss()
                    toast.error('Unprocessable Entity: The request was well-formed but cannot be processed');
                } else if (error.response.status === 500) {
                    toast.dismiss()
                    toast.error('Internal Server Error: An unexpected error occurred');
                } else {
                    toast.dismiss()
                    toast.error('Unknown Error: An unexpected error occurred');
                }
            } else if (error.request) {
                toast.dismiss()
                toast.error('Network Error: Unable to connect to the server');

            } else {
                toast.dismiss()
                toast.error('An unexpected error occurred');
            }
            setLoading(false)
            close()
        }
    }

    return (


        <div>
            <div>{children}</div>
            <div>
                {DeleteData && DeleteData.status && <div onClick={() => { close() }} className='fixed z-9999 opacity-40 top-0 right-0 left-0 bottom-0 w-full bg-black'></div>}
                <PopupBox
                    Loading={Loading}
                    confirmButtonText={'Delete'}
                    onConfirmClick={DeleteAPIFunction}
                    isthirdButton={isthirdButton}
                    thirdButtonLabel={' Deactivate'}
                    onthirdButtonClick={UpdateAPIFunction}
                    close={close}
                    onCancel={() => {
                        close()
                    }}
                    isOpen={DeleteData && DeleteData.status} title={DeleteData && DeleteData.title} message={DeleteData && DeleteData.message} />
            </div>
        </div>
    )
}

export default DeleteDataAPIContainer