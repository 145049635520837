import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../utils/authContext'
import GetDataAPIContainer from '../../../layouts/api/get.container'
import ToogleButton from '../../../components/Public/Widgets/toogle.widget'
import PrimaryButton from '../../../components/Public/Widgets/button.primary'
import { BsCalendarEvent, BsClock, BsLock, BsPhone } from 'react-icons/bs'
import { CiLock, CiUser } from 'react-icons/ci'
import { MdDeleteOutline, MdEmail, MdError, MdPassword } from 'react-icons/md'
import PrimaryInput from '../../../components/Public/Widgets/input.primary'
import { PutDataAPIFunction } from '../../../layouts/api/put.function'
import { IoCloudUploadOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'
import { Popover } from '@headlessui/react'
import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { PostDataAPIFunction } from '../../../layouts/api/post.function'


const Account = ({ refreshIframe }) => {



  const [PasswordChange, setPasswordChange] = useState({
    old_password: '',
    new_password: ''
  })


  useEffect(() => {
    console.log(PasswordChange)
  })
  const { Auth } = useContext(AuthContext)
  const [Refresh, setRefresh] = useState(false)
  const [user, setUser] = useState({})
  const [currentUser, setcurrentUser] = useState({
    image: null
  })
  const [PasswordButtonLoading, setPasswordButtonLoading] = useState(false)
  const [isVerficationDisabled, setIsVerficationDisabled] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [isPin, setPin] = useState(true)
  const [EmailStateSending, setEmailStateSending] = useState(false)

  const sendVerficationEmail = async () => {
    setIsVerficationDisabled(true)

    try {

      setEmailStateSending(true)

      const APIResponse = await PostDataAPIFunction({
        Recieveddata: {
          username: currentUser.username,
          email: currentUser.email,
          user_id: currentUser._id
        },

        callfor: 'auth/send/verfication',

      })

      if (APIResponse.success && APIResponse.data.status === 200) {

        setTimeout(() => {
          setEmailStateSending(false)
        }, 2000);
      }

      else {
        setEmailStateSending(false)
      }

    } catch (error) {
      console.error(error);
      setLoading(false);
    }

    setTimeout(() => {
      setIsVerficationDisabled(false);
    }, 20000);
  }

  const handlePasswordChange = async () => {


    if (PasswordChange.old_password !== '' && PasswordChange.new_password !== '') {

      setPasswordButtonLoading(true)

      const APIResponse = await PutDataAPIFunction({
        token: Auth.token,
        Recieveddata: {
          old_password: PasswordChange.old_password,
          new_password: PasswordChange.new_password,
        },

        callfor: 'users/password-change',

      })

      if (APIResponse.success && APIResponse.data.status === 200) {

        setTimeout(() => {
          setPasswordButtonLoading(false)
          close()
        }, 2000);

        setPasswordChange({
        })
      }

      else {
        setPasswordButtonLoading(false)
        close()
        setPasswordChange({
        })
      }

    }
    else {
      toast.info('Fill the required fields')
    }

  }

  useEffect(() => {
    refreshIframe()
  }, [Refresh])

  let [isOpen, setIsOpen] = useState(false)

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  useEffect(() => {

    if (currentUser && currentUser.is_password_protected) {
      setPin(true)
    }
    else {
      setcurrentUser({
        ...currentUser,
        profile_password: ''
      })
      setPin(false)
    }

  }, [currentUser && currentUser.is_password_protected])

  function formatProfileCreationDate(isoDateString) {
    const date = new Date(isoDateString);

    const datedata = {
      year: 'numeric',
      month: 'long', // e.g., "October"
      day: 'numeric',
    };

    const timedata = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // Use 12-hour format
    };

    return { date: date.toLocaleString('en-US', datedata), time: date.toLocaleString('en-US', timedata).replace(/ GMT.*$/, '') }
  }

  useEffect(() => {
    console.log(user)
    setcurrentUser(user)
  }, [user])

  useEffect(() => {
    if (JSON.stringify(user) == JSON.stringify(currentUser)) {
      setIsEditable(false)
    }
    else {
      setIsEditable(true)
    }
  }, [currentUser])

  const changeValue = (key, value) => {
    setcurrentUser({
      ...currentUser,
      [key]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('image', currentUser.image);

    formData.append('email', currentUser.email);
    formData.append('phone', currentUser.phone);
    formData.append('first_name', currentUser.first_name);
    formData.append('last_name', currentUser.last_name);

    formData.append('is_password_protected', currentUser.is_password_protected);
    formData.append('profile_password', currentUser.profile_password);
    formData.append('bio', currentUser.bio);

    if (currentUser.is_password_protected) {
      if (currentUser.profile_password.length !== 4) {
        setLoading(false);
        toast.error('PIN Code must be exactly 4 digits')
      }

      else {

        try {

          const APIResponse = await PutDataAPIFunction({

            token: Auth.token,
            Recieveddata: formData,
            callfor: 'users',
          })

          if (APIResponse.success && APIResponse.data.status === 200) {
            setLoading(false);
            setRefresh(!Refresh)
          }

          else {
            setLoading(false);
          }

        } catch (error) {
        }
      }
    }
    else {
      try {

        const APIResponse = await PutDataAPIFunction({

          token: Auth.token,
          Recieveddata: formData,
          callfor: 'users',
        })

        if (APIResponse.success && APIResponse.data.status === 200) {
          setLoading(false);
          setRefresh(!Refresh)
        }

        else {
          setLoading(false);
        }

      } catch (error) {
      }
    }

  }


  //     else {

  // }
  //   }


  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const finalImage = image
      setcurrentUser({
        ...currentUser,
        image: finalImage
      })
    }

  }

  const chooseImage = () => {
    const fileInput = document.getElementById('equipment-image-uploader');
    fileInput.click()
  }


  return (
    <div className='h-full'>
      <div className='text-primary-500 font-semibold pb-4 text-lg'>Your Profile</div>
      <GetDataAPIContainer RefreshData={Refresh} callfor={'users'} SingleId={''} ScreenName={'Profile'} data={user} setData={setUser}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className='lg:flex justify-stretch w-full'>

            <div className='cursor-pointer flex justify-start mr-4 mb-2 rounded-lg'>
              <div className='cursor-pointer relative lg:flex w-full h-[345px] mb-6 lg:mb-0 lg:h-32 lg:w-32 border-2 border-dotted border-stroke dark:border-form-strokedark rounded-lg'>

                <input accept=".png, .jpg, .jpeg, .webp" onChange={(e) => handleImageChange(e)} className='hidden w-full h-full rounded-lg border-0 outline-0' type="file" name="" id="equipment-image-uploader" />

                {(currentUser && currentUser.image && currentUser.image instanceof File) ?
                  <img onClick={chooseImage} className='absolute top-0 left-0 w-full h-full object-cover rounded-lg bg-url("https://placehold.co/600x400/white/white")' src={URL.createObjectURL(currentUser.image)} alt={currentUser.image && currentUser.image.name} /> :
                  currentUser && currentUser.image && <img className='absolute top-0 left-0 w-full h-full object-cover rounded-lg bg-url("https://placehold.co/600x400/white/white")' src={currentUser.image} alt="" />
                }
                {currentUser && currentUser.image !== null ?
                  <div className='absolute top-2 right-2 gap-2 text-white text-xl duration-300 rounded-lg'>
                    <div className='flex gap-2'>
                      <div onClick={() => {
                        changeValue('image', null)
                        const fileInput = document.getElementById('equipment-image-uploader');
                        fileInput.value = ''
                        console.log('Deleted')
                      }} className='p-2 rounded-full bg-red-500 hover:bg-red-600 cursor-pointer hover:scale-105  duration-500'> <MdDeleteOutline /> </div>

                    </div>

                  </div> :
                  <div onClick={chooseImage} className='absolute w-full h-full flex flex-col justify-center items-center text-primary dark:text-white text-xl duration-300 rounded-lg border-0'>
                    <div className='cursor-pointer absolute text-4xl text-stroke dark:text-form-strokedark text-center gap-1 items-center justify-center flex flex-col'>
                      <IoCloudUploadOutline />
                      <div className='text-[12px] text-slate-300 dark:text-slate-500 leading-5'> Upload Image<br />Image</div>
                    </div>
                  </div>

                }
              </div>
            </div>
            <div className='grow'>
              <div className='relative'>

                <PrimaryInput value={currentUser && currentUser.email} onChange={(e) => changeValue('email', e.target.value)} icon={<MdEmail />} type={'text'} placeholder={'Enter Email'} label={'Email'} name={'email'} size={'sm'} />


                {currentUser && !currentUser.email_verfied && currentUser.email_verfied == false && <div className='absolute right-2 top-3 bottom-3'>
                  <Popover className={'relative'}>
                    {({ open, close }) => (
                      <>
                        <Popover.Button className="block text-2xl font-semibold text-red-500 focus:outline-none data-[active]:text-red-400 data-[hover]:text-red-400 data-[focus]:outline-1 data-[focus]:outline-white">
                          <MdError />
                        </Popover.Button>
                        <Popover.Panel
                          transition
                          anchor={{ to: 'bottom end', gap: '0px' }}
                          className="divide-y z-[99999] rounded-lg text-slate-700 bg-slate-100 shadow-lg text-sm/6 p-4 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
                        >
                          <div>
                            <div className='pb-2 text-sm'>Email not Verified</div>
                            <PrimaryButton
                              loading={EmailStateSending}
                              disabled={isVerficationDisabled}
                              className={'bg-red-500 hover:bg-red-400'}
                              onClick={async () => {
                                await sendVerficationEmail();
                                setEmailStateSending(true)
                                // setTimeout(() => {

                                close();
                                // }, 2000);
                              }}
                              size={'sm'}
                              label={isVerficationDisabled ? 'Wait a few seconds' : 'Send Verification'}
                            />
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>

                </div>}
              </div>
              <div className='flex gap-4 items-center '>
                <PrimaryInput inputMode={'numeric'} disabled={!isPin} value={currentUser && currentUser.profile_password} onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/g, '');
                  if (newValue.length <= 4) {
                    changeValue('profile_password', newValue)
                  }

                }
                } icon={<CiLock />} type={'text'} pattern={'[0-9]*'} maxLength="4" placeholder={'Enter 4 Digit Profile PIN'} label={'Profile PIN'} name={'profile_password'} size={'sm'} />
                <ToogleButton onToogle={() => {
                  setcurrentUser({ ...currentUser, is_password_protected: !currentUser.is_password_protected })
                }} value={currentUser && currentUser.is_password_protected} />
              </div>
            </div>
          </div>

          <div className='flex gap-4'>
            <PrimaryInput value={currentUser && currentUser.first_name} onChange={(e) => changeValue('first_name', e.target.value)} icon={<CiUser />} type={'text'} placeholder={'Enter First name'} label={'First name'} name={'first_name'} size={'sm'} />
            <PrimaryInput value={currentUser && currentUser.last_name} onChange={(e) => changeValue('last_name', e.target.value)} icon={<CiUser />} type={'text'} placeholder={'Enter Last Name'} label={'Last Name'} name={'last_name'} size={'sm'} />
          </div>
          <PrimaryInput value={currentUser && currentUser.bio} onChange={(e) => changeValue('bio', e.target.value)} type={'text'} placeholder={'Enter Profile bio'} label={'Bio'} name={'bio'} size={'sm'} />
          <PrimaryInput value={currentUser && currentUser.phone} onChange={(e) => changeValue('phone', e.target.value)} icon={<BsPhone />} type={'text'} placeholder={'Enter Phone Number'} label={'Phone Number'} name={'phone'} size={'sm'} />

          <div className='flex flex-col lg:flex-row gap-2 items-start justify-between lg:items-center py-4 text-xs text-slate-400'> <span className='flex flex-col lg:flex-row gap-2'> You have been a member since:<span className='flex gap-2 items-center'><BsCalendarEvent className='text-primary' /> {user && formatProfileCreationDate(user.created_at).date} </span><span className='flex gap-2 items-center'> <BsClock className='text-primary' /> {user && formatProfileCreationDate(user.created_at).time}</span></span>
            {/* <div className='text-blue-600 cursor-pointer hover:text-blue-700'>Change Password</div> */}

            <Button
              onClick={open}
              className='text-blue-600 cursor-pointer hover:text-blue-700'
            // className="rounded-md bg-black/20 py-2 px-4 text-sm font-medium text-white focus:outline-none data-[hover]:bg-black/30 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Change Password
            </Button>

            <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
              <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-black bg-opacity-50"> {/* Add overlay here */}
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4">
                    <DialogPanel
                      transition
                      className="w-full max-w-md rounded-lg border-[1px] border-slate-100 shadow-lg bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
                    >
                      <DialogTitle as="h3" className="text-base/7 font-medium text-primary">
                        Change Password
                      </DialogTitle>
                      <p className="mt-2 text-sm/6 text-slate-500">
                        Secure your account by updating your password regularly. Choose a strong, unique password to keep your data safe.
                      </p>
                      <div className='flex flex-col gap-1 py-2'>
                        <PrimaryInput value={PasswordChange.old_password} onChange={(e) => { setPasswordChange({ ...PasswordChange, old_password: e.target.value }) }} icon={<MdPassword />} type={'text'} placeholder={'Enter Old Password'} label={'Old Password'} name={'old-password'} size={'sm'} />
                        <PrimaryInput value={PasswordChange.new_password} onChange={(e) => { setPasswordChange({ ...PasswordChange, new_password: e.target.value }) }} icon={<MdPassword />} type={'text'} placeholder={'Enter New Password'} label={'New Password'} name={'new-password'} size={'sm'} />
                      </div>
                      <div className="mt-4">
                        <PrimaryButton
                          loading={PasswordButtonLoading}
                          size={'base'}
                          label={'Change Password'}
                          onClick={handlePasswordChange}>
                        </PrimaryButton>
                      </div>
                    </DialogPanel>
                  </div>

                </div>
              </div>
            </Dialog>
          </div>

          <PrimaryButton loading={loading} onClick={(e) => { handleSubmit(e) }} type={'submit'} size={'base'} disabled={!isEditable} label={'Update'} />
        </form>
      </GetDataAPIContainer>
    </div>
  )
}

export default Account