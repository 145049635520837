import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { AuthContext } from '../../utils/authContext'
import Loader from '../../components/Public/Widgets/loader'
import NoData from '../../components/Public/Widgets/nodata.container'



const GetDataAPIContainer = ({ children, noDataContainer, RefreshData, SingleId, ScreenName, data, setData, callfor, noeffectifEmpty }) => {

    const { Auth } = useContext(AuthContext)

    const EnteredAPIFunction = async (id) => {
        console.log(process.env.REACT_APP_SERVER_URL)
        setLoading(true)
        try {
            const response = await axios.create({ baseURL: process.env.REACT_APP_SERVER_URL }).get('/api/' + callfor + '/' + id,
                {
                    headers: {
                        Authorization: `Bearer ${Auth?.token}`,
                    }
                });
            setLoading(false)
            return response.data.data;

        } catch (error) {
            if (error.response) {
                if (error.response?.data.message) {
                    toast.dismiss()
                    toast.error(error.response.data.message)
                }
                else {

                    if (error.response.status === 400) {
                        toast.dismiss()
                        toast.error('Bad Request: The request was invalid or cannot be processed');
                    } else if (error.response.status === 401) {
                        toast.dismiss()
                        setError('Authorization')


                        toast.error('Unauthorized: You are not authorized to access this resource');
                    } else if (error.response.status === 403) {
                        // console.log(error.response)
                        toast.dismiss()
                        setError('Permission')

                        toast.error('Forbidden: You do not have permission to access this resource');
                        // }
                    } else if (error.response.status === 404) {
                        toast.dismiss()
                        // toast.error('Not Found: The requested resource could not be found');
                    } else if (error.response.status === 405) {
                        toast.dismiss()
                        setError('Request')
                        toast.error('Method Not Allowed: The requested method is not supported');
                    } else if (error.response.status === 408) {
                        toast.dismiss()
                        toast.error('Request Timeout: The request timed out');
                    } else if (error.response.status === 422) {
                        toast.dismiss()
                        toast.error('Unprocessable Entity: The request was well-formed but cannot be processed');
                    } else if (error.response.status === 500) {
                        toast.dismiss()
                        toast.error('Internal Server Error: An unexpected error occurred');
                    } else {
                        toast.dismiss()
                        toast.error('Unknown Error: An unexpected error occurred');
                    }
                }
            } else if (error.request) {
                toast.dismiss()
                setError('Network')
                toast.error('Network Error: Unable to connect to the server');

            } else {
                toast.dismiss()
                toast.error('An unexpected error occurred');
            }
            setLoading(false)
        }
    };
    const [Loading, setLoading] = useState(false);
    const [Error, setError] = useState(ScreenName);

    useEffect(() => {
        EnteredAPIFunction(SingleId).then((AllData) => {
            setData(AllData);
        });
        console.log(data)
    }, [SingleId, RefreshData]);

    if (Loading) {
        return <Loader />
    }
    else if (data) {
        console.log(data)
        return <div>
            {children}
        </div>
    }
    else {
        return noDataContainer ? (noDataContainer) : (<NoData item={Error} />)
    }

}

export default GetDataAPIContainer