import React, { useContext, useState } from 'react'
import RightSidebar from '../components/Dashboard/SideBar/right.sidebar'
import LeftSidebar from '../components/Dashboard/SideBar/left.sidebar'
import MobileHeader from '../components/Dashboard/Header/mobile.header';
import { AuthContext } from '../utils/authContext';

const DashboardLayout = ({ children, iframeSrc }) => {

    const [sidebarOpen, setSidebarOpen] = useState(true);


    return (
        <>
            <div id='dashboard' className="dark:bg-boxdark-2 dark:text-bodydark w-full relative">
                <div className="flex h-screen ">
                    <LeftSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                    <main className='no-scrollbar flex flex-col w-screen p-4 mb-16 lg:mb-0 md:p-6 2xl:p-10 overflow-y-scroll overflow-x-hidden lg:mx-72.5'>
                        <MobileHeader />
                        {children}
                    </main>
                    <RightSidebar iframeSrc={iframeSrc} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </div>
            </div>
        </>
    )
}

export default DashboardLayout