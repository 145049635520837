import { Navigate, Route, Routes } from "react-router-dom"
import ResetPassword from "./reset_password"
import SetNewPassword from "./set_new_password"
import { Helmet } from "react-helmet"

const ForgetPassword = () => {
    return (
        <Routes>

            <Route path="/*" element={<Navigate to="/forget-password/send-verfication-link" replace />} />

            <Route path='/send-verfication-link/' element={
                <>
                    <Helmet>
                        <title>Send Verfication Link - Reset Your Password</title>
                        <meta
                            name="description"
                            content="Send Verfication Link - Reset Your Password"
                        />
                    </Helmet>
                    <ResetPassword />
                </>
            }
            />

            <Route path='/set-new-password/' element={
                <>
                    <Helmet>
                        <title>Set New Password - Reset Your Password</title>
                        <meta
                            name="description"
                            content="Set New Password - Reset Your Password"
                        />
                    </Helmet>
                    <SetNewPassword />
                </>
            }
            />
        </Routes>
    )

}

export default ForgetPassword