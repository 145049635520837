import React from 'react'
import { CiTextAlignLeft } from "react-icons/ci";


const PrimaryInput = ({ size, name, label, placeholder, type, icon, value, onChange, onClick, disabled, pattern, maxLength, inputMode }) => {
    return (
        <div className='relative w-full'>
            <div className="relative w-full my-2">
                <div className='text-slate-300 focus-within:text-slate-400 dark:text-slate-300 dark:focus-within:text-slate-100  duration-500'>
                    {label && <label className='text-[10px]  px-2 z-10 absolute left-8 -top-[6px]  bg-white dark:bg-background-500' htmlFor={name}>{label ? label : 'Primary Label'}</label>}
                    <div className='text-primary-200 focus-within:text-primary-500 stroke-primary-200 stroke-2 focus-within:stroke-primary-500'>
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none  ">
                            {icon ? icon : <CiTextAlignLeft />}
                        </div>
                        <input inputMode={inputMode ? inputMode : null} maxLength={maxLength ? maxLength : null} pattern={pattern ? pattern : null} disabled={disabled ? disabled : false} onChange={onChange} value={value ? value : ''} name={name} type={type ? type : 'text'} id="default-search"
                            className={`block w-full text-sm font-normal shadow-xs  text-slate-900 dark:text-slate-200 bg-transparent border border-slate-200 focus-within:border-slate-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed  ${size == 'sm' ? 'py-3 pl-10 pr-4' : 'py-5 pl-10 pr-4'}`}
                            placeholder={placeholder ? placeholder : 'Primary Input Placeholder'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrimaryInput